import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import bdg1 from "assets/badges/bdg1.png"
import bdg2 from "assets/badges/bdg2.png"
import bdg3 from "assets/badges/bdg3.png"
import bdg4 from "assets/badges/bdg4.png"

const ThreeColumnContainer = styled.div `
  ${tw`flex flex-col items-center lg:flex-row flex-wrap lg:justify-center max-w-screen-xl mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4 max-w-xs`}
`;
  const ContainerFullWidth = tw.div`bg-primary-600/[0.5] -mb-8 mt-8 px-8`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}
  .imageContainer {
    ${tw`text-center rounded-full p-4`}
  }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = [
    { 
      imageSrc: bdg2,
    },
    {
      imageSrc: bdg1,
    },
    {
      imageSrc: bdg4,
    },
    {
      imageSrc: bdg3,
    },
  ],
  imageContainerCss = null,
  imageCss = null,
}) => {
  return (
    <ContainerFullWidth>
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" className="onoverimg" css={imageCss} />
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
    </ContainerFullWidth>
  );
};