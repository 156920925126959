import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-1.svg";

//icons image
import BookKeepingIcon from "assets/serviceIcons/bookkeeping-services-01.svg";
import IncomTaxIcon from "assets/serviceIcons/income-tax-01.svg";
import PayrollIcon from "assets/serviceIcons/payroll-services-01.svg";
import TaxplanningIcon from "assets/serviceIcons/tax-planning-01.svg";
import CorporateIcon from "assets/serviceIcons/corporate-tax-01.svg";
import FinancialPlanningIcon from "assets/serviceIcons/financial-planning-services-01.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(
  SectionDescription
)`mt-4 text-sm px-4 lg:px-0 md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-justify lg:text-center max-w-5xl`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3  max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center  sm:text-left w-full h-full px-2 py-8`}
  .imageContainer {
    ${tw` p-5 text-center flex-shrink-0`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide text-primary-300 font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 px-2 lg:px-0 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-15 transform translate-x-32 translate-y-48 `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = null,
  heading = "Our Services",
  subheading = "About",
  description = "Expert Taxation Services to Optimize Your Business. Our Tailored Solutions include Accounting, Taxation, Bookkeeping, Payroll, and more. Trust Our Team to Maximize Your Financial Potential in Perth, WA.",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: IncomTaxIcon,
      title: "Tax Returns",
      description:
        "Our tax return service in Perth, WA ensures your tax obligations are met with the Australian Taxation Office. We provide efficient and accurate tax preparation, lodging, and compliance services. Contact us today.",
    },
    {
      imageSrc: CorporateIcon,
      title: "Business Statement",
      description:
        "Our Business Activity Statement (BAS) service in Perth, WA helps your business comply with the ATO. We provide accurate BAS preparation, lodgement, and compliance services.",
    },

    {
      imageSrc: TaxplanningIcon,
      title: "Financial Statements",
      description:
        "Our Financial Statements service in Perth, WA provides accurate and insightful financial reporting to help you make informed business decisions. Contact us for your tailored needs.",
    },

    {
      imageSrc: BookKeepingIcon,
      title: "Bookkeeping Services",
      description:
        "Our bookkeeping services in Perth, WA provide accurate and timely financial records for your business. We offer customized solutions for record-keeping, reconciliations, and compliance.",
    },

    {
      imageSrc: PayrollIcon,
      title: "Payroll Services",
      description:
        "As a trusted payroll service provider in Perth, WA, we offer accurate and reliable payroll solutions. Our customized services include processing, compliance, and reporting to ensure your business runs smoothly.",
    },

    {
      imageSrc: FinancialPlanningIcon,
      title: "Advisory Services",
      description:
        "Our advisory services in Perth, WA provide expert accounting and taxation advice for your business. We offer tailored solutions for strategic planning, risk management, and financial analysis.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">
                  <h4>{card.title || "Fully Secure"}</h4>
                </span>
                <p className="description" tw="text-justify">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
