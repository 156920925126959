import React from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { inquiry } from "MetaTags";
import { css } from "styled-components/macro";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import { Container } from "react-bootstrap";
import breadImg from "assets/make-an-inquiry-brdcrms.jpeg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import InquiryForm from "components/InquiryForm";
import InquiryForm2 from "components/InquiryForm";
import Header2 from "components/headers/Header2";

const InquiryPage = () => {
  const Heading = tw.h1`text-4xl sm:text-5xl text-primary-300 font-black tracking-wide text-left`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h2`mt-10 text-left sm:text-xl md:text-2xl text-primary-300 lg:text-3xl`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
    backgroundSize: "cover",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{inquiry.title}</title>
        <meta name="description" content={inquiry.description} />
        <link rel="canonical" href={inquiry.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>
            MAKE
            <HighlightedText> AN INQUIRY</HighlightedText>
          </Heading>
          <h4>
            Your business deserves the best accounting services. <br /> Trust us
            to provide you with accurate, timely and reliable solutions.
          </h4>
        </div>
      </section>
      <Container className="d-flex align-items-center flex-column">
        <Subheading>How Can We Help You ?</Subheading>
        <Description>
          Please complete the following form so we can get a better
          understanding of your needs.
        </Description>
        <div className="mt-5 mb-5 width-inquiry">
          <InquiryForm2 />
          <Description>
            * We value your privacy and never share your information with third
            parties. Your trust is important to us.
          </Description>
        </div>
      </Container>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
    </div>
  );
};

export default InquiryPage;
