import React from "react";
import tw from "twin.macro";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import MetaTags from "react-meta-tags";
import { advisory } from "MetaTags";
import ContactUsCp from "components/ContactUsCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import SoftwareSection from "components/SoftwareSection";

const AdvisoryService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{advisory.title}</title>
        <meta name="description" content={advisory.description} />
        <link rel="canonical" href={advisory.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>TAX ADVISORY SERVICES</Heading>
          <h4>
            Tax advisory services refer to professional guidance provided by
            experts <br /> to individuals or businesses in regards to their tax
            obligations
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              BEST TAX <br /> ADVISORS
            </h2>
            <div className="vertical-line"></div>
            <p>
              Leverage the expertise of our tax advisors and gain peace of mind,
              allowing you to focus solely on your core business activities.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          Understanding Tax Advisory Services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Tax advisory services are professional consulting services provided to
          individuals, businesses, or organizations to help them comply with tax
          laws and regulations while minimizing their tax liability.
          <br />
          The service may be provided by a certified public accountant, tax
          attorney, or a tax advisor who has a deep understanding of the tax
          laws and regulations.
          <br />
          The tax advisory services include tax planning, tax compliance, tax
          dispute resolution, tax audit defense, and tax strategy.
          <br />
          These services help businesses and individuals to navigate the
          complexities of tax laws and regulations, reduce their tax
          liabilities, avoid penalties and fines, and achieve their accounting
          goals. Tax advisory services are critical for any business or
          individual to manage their finances effectively while ensuring
          compliance with the tax laws.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <TaxAdvisoryServicesCp />
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default AdvisoryService;
