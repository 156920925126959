import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { ias } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const IASService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{ias.title}</title>
        <meta name="description" content={ias.description} />
        <link rel="canonical" href={ias.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>Instalment Activity Statements (IAS)</Heading>
          <h4>
            Installment Activity Statement is a form of tax reporting used by
            <br />
            Australian businesses to report and pay tax obligations in advance.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              ACCOUNTING <br />
              AGENCY
            </h2>
            <div className="vertical-line"></div>
            <p>
              We offer IAS services to help businesses report and pay <br />
              their tax obligations in advance and manage cash flow.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Instalment Activity Statements (IAS) services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Our Installment Activity Statement (IAS) service is designed to assist
          Australian businesses in meeting their tax obligations by providing
          accurate and timely lodgment of their IAS. As a part of the Pay As You
          Go (PAYG) tax system, IAS enables businesses to make advance payments
          towards their tax obligations throughout the year, which can help them
          manage their cash flow more effectively. IAS lodgments are mandatory
          for businesses with an Australian Business Number (ABN) or those that
          are registered for goods and services tax (GST). Our service ensures
          that all relevant information, such as GST, PAYG installments, and
          other taxes owed, is accurately reported on the IAS.
          <br />
          <br />
          Failing to lodge an IAS on time can result in penalties and interest
          charges, which can have a negative impact on a business's cash flow.
          As such, it is essential to stay up to date with IAS lodgments, and
          our service helps businesses to do just that. We provide a hassle-free
          IAS lodgment service, allowing businesses to focus on their core
          operations. Our team of experienced professionals is well-versed in
          the complexities of the PAYG tax system and can provide expert
          guidance and advice to businesses. We can also assist with IAS payment
          processing and ensure that payments are made on time to avoid any
          penalties or interest charges.
          <br />
          <br />
          Failing to lodge an IAS on time can result in penalties and interest
          charges, which can have a negative impact on a business's cash flow.
          As such, it is essential to stay up to date with IAS lodgments, and
          our service helps businesses to do just that. We provide a hassle-free
          IAS lodgment service, allowing businesses to focus on their core
          operations. Our team of experienced professionals is well-versed in
          the complexities of the PAYG tax system and can provide expert
          guidance and advice to businesses. We can also assist with IAS payment
          processing and ensure that payments are made on time to avoid any
          penalties or interest charges. In summary, our IAS service provides
          businesses with the peace of mind that their tax obligations are being
          met accurately and on time, enabling them to focus on running their
          operations and growing their businesses.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            How Fortune preapres your IAS
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            IAS, or Installment Activity Statement, is a taxation system that
            Australian businesses can use to report and pay their tax
            obligations in advance. It is a part of the Pay As You Go (PAYG) tax
            system and enables businesses to manage their cash flow more
            effectively by making smaller, more frequent payments throughout the
            year. Businesses that have either an Australian Business Number
            (ABN) or are registered for goods and services tax (GST) are
            required to lodge IAS. The statement is used to report the amounts
            of GST, PAYG installments, and other taxes owed. Failing to lodge an
            IAS on time can result in penalties and interest charges, making it
            crucial for businesses to stay up to date with their lodgments.
            <br />
            <br />
            IAS lodgments are usually made quarterly, with the due dates being
            the 21st day of the month following the end of the relevant quarter.
            However, some businesses may be required to lodge more frequently.
            Proper management of IAS lodgments is crucial to ensure compliance
            with taxation laws and to avoid any penalties or interest charges.
            Seeking professional advice and assistance can help businesses to
            stay up to date with their IAS lodgments and manage their tax
            obligations more efficiently.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default IASService;
