import React from "react";
import tw from "twin.macro";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { bookkeeping } from "MetaTags";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const BookkeepingService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{bookkeeping.title}</title>
        <meta name="description" content={bookkeeping.description} />
        <link rel="canonical" href={bookkeeping.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>BOOKKEEPING SERVICES</Heading>
          <h4>
            The process of recording, classifying, <br /> and organizing
            financial transactions to produce accurate financial statements.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              BEST <br /> BOOKKEEPERS
            </h2>
            <div className="vertical-line"></div>
            <p>
              Skilled accounting bookkeeping for businesses, <br /> ensuring
              timely and accurate financial records to make informed decisions.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Bookkeeping Accounting Services for Your Business Finance
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Bookkeeping is the process of recording and organizing financial
          transactions of a business. It involves keeping track of all income
          and expenses, receipts, invoices, and other financial documents to
          ensure accurate financial reporting. Bookkeeping is a vital part of
          any business, as it helps business owners keep track of their
          financial status and make informed decisions based on that
          information.
          <br />
          Bookkeeping involves a variety of tasks such as data entry, bank
          reconciliations, accounts payable and accounts receivable management,
          payroll processing, and financial reporting. These tasks may seem
          daunting for small business owners, but outsourcing bookkeeping
          services can help take the burden off their shoulders.
          <br />
          By outsourcing bookkeeping services to professionals, business owners
          can free up their time and resources to focus on other aspects of
          their business. Additionally, outsourcing bookkeeping services ensures
          that all financial transactions are accurately recorded, which helps
          to prevent errors and potential accounting problems down the line.
          Overall, bookkeeping services are essential for any business, no
          matter its size. With accurate financial reporting and analysis,
          business owners can make informed decisions that help them achieve
          their goals and ensure long-term success.
        </div>
      </Container>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">Our Services</Subheading>
        <Heading2 className="text-center">
          List of Bookkeeping Services We Offer
        </Heading2>
        <p tw="text-left">
          Our bookkeeping services in Perth provide accurate and timely
          financial data that can help businesses make informed decisions. Some
          of the key benefits of our bookkeeping services include:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Accurate Record-Keeping:</h4>
          </li>
          <p>
            Our bookkeeping experts maintain accurate and up-to-date records of
            all financial transactions, including income, expenses, and
            payments.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Timely Financial Reporting:</h4>
          </li>
          <p>
            We provide timely financial reports, including profit and loss
            statements, balance sheets, and cash flow statements, which help
            businesses monitor their accounting performance and make informed
            decisions.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Improved Cash Flow Management:</h4>
          </li>
          <p>
            With our bookkeeping services, businesses can better manage their
            cash flow by tracking their income and expenses, and identifying
            areas where they can reduce costs.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Compliance with Regulatory Requirements:</h4>
          </li>
          <p>
            Our bookkeeping experts ensure that businesses comply with all
            regulatory requirements, including tax laws, by maintaining accurate
            and complete financial records.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>More Time for Business Owners:</h4>
          </li>
          <p>
            By outsourcing bookkeeping services to our team, business owners can
            focus on core business activities and other areas of their business
            that need their attention.
          </p>
        </ul>
        <p tw="text-left">
          At our firm, we understand that every business has unique needs, which
          is why we offer personalized bookkeeping services tailored to meet the
          specific needs of our clients.
        </p>
      </Container>
      <SoftwareSection />

      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default BookkeepingService;
