import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { financialStatements } from "MetaTags";
import { css } from "styled-components/macro";
import { useEffect } from "react";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import RegistrationServiceCp from "components/FinancialStatementsCp";
import FinancialStatementsCp from "components/FinancialStatementsCp";
import SoftwareSection from "components/SoftwareSection";

const FinancialStatments = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{financialStatements.title}</title>
        <meta name="description" content={financialStatements.description} />
        <link rel="canonical" href={financialStatements.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>FINANCIAL STATEMENTS</Heading>
          <h4>
            Financial statement shows a company's financial performance and
            condition, <br /> including assets, liabilities, revenues, expenses,
            and cash flows.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              TAX ACCOUNTING <br /> FIRMS
            </h2>
            <div className="vertical-line"></div>
            <p>
              We specialize in providing accurate and timely financial
              statements that help <br /> our clients gain a clear understanding
              of their financial standing.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          Financial Statements preparation services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Financial statements are important documents that provide an overview
          of a company's financial health. They include several key components,
          each of which provides important information for investors, lenders,
          and management:
          <br />
          <br />
          1. The balance sheet shows a company's assets, liabilities, and equity
          at a specific point in time. It provides a snapshot of the company's
          financial position and shows how its resources are being utilized.
          <br />
          <br />
          2. The income statement shows a company's revenues, expenses, and net
          income over a period of time. It provides insight into a company's
          profitability and how well it is generating revenue and managing
          costs.
          <br />
          <br />
          3. The cash flow statement shows how a company generates and uses cash
          over a period of time. It provides insight into a company's ability to
          generate cash from operations, invest in new projects, and pay
          dividends to shareholders.
          <br />
          <br />
          4. The statement of changes in equity shows how the company's equity
          changed over the period covered by the financial statement. This
          statement includes information about changes in share capital,
          reserves, and retained earnings.
          <br />
          <br />
          Overall, financial statements are an essential tool for evaluating a
          company's financial health and making informed decisions about
          investments, loans, and other financial matters.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <FinancialStatementsCp />
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default FinancialStatments;
