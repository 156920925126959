import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { financialStrategies } from "MetaTags";
import { css } from "styled-components/macro";
import { useEffect } from "react";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import FinancialStategiesCp from "components/FinancialStategiesCp";
import SoftwareSection from "components/SoftwareSection";

const FinancialStrategies = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{financialStrategies.title}</title>
        <meta name="description" content={financialStrategies.description} />
        <link rel="canonical" href={financialStrategies.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>FINANCIAL STRATEGIES</Heading>
          <h4>
            Financial strategies are plans and actions to help individuals or
            businesses achieve <br /> their financial goals and objectives.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              Professional <br /> Tax Advisors
            </h2>
            <div className="vertical-line"></div>
            <p>
              We offer businesses our expertise in accounting strategies to
              achieve their accounting goals by <br /> analyzing data,
              identifying opportunities, and implementing effective solutions.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          Financial strategies for business
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Financial strategies are essential for individuals and businesses to
          manage their accounts effectively. These strategies involve setting
          financial goals, creating a budget, managing debt, and investing
          wisely. A financial strategy takes into account factors such as
          income, expenses, savings, and investment goals. It is designed to
          help individuals and businesses make informed financial decisions and
          achieve financial stability.
          <br />
          Financial strategies also include tax planning, retirement planning,
          and risk management. Tax planning involves understanding tax laws and
          taking advantage of tax deductions and credits to minimize tax
          liabilities. Retirement planning helps individuals plan for their
          financial needs after they retire, while risk management involves
          identifying potential financial risks and taking steps to mitigate
          them. At our firm, we provide personalized taxation advisory services
          that help our clients create and implement effective accounting
          strategies that align with their unique goals and needs.
        </div>
      </Container>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">Our Services</Subheading>
        <Heading2 className="text-center">
          Main Types of Financial Strategies
        </Heading2>
        <p tw="text-left">
          Financial strategies refer to a set of actions and decisions that help
          individuals or organizations achieve their financial goals. There are
          various types of financial strategies that can be used to achieve
          financial objectives. Some of the main types of financial strategies
          are:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Investment strategies:</h4>
          </li>
          <p>
            These are strategies that involve investing money in various
            financial instruments such as stocks, bonds, mutual funds, and real
            estate to generate returns and build wealth.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Debt management strategies:</h4>
          </li>
          <p>
            These strategies help individuals and organizations manage their
            debt by creating a plan to repay outstanding debts, reducing
            interest rates, and avoiding additional debt.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Risk management strategies:</h4>
          </li>
          <p>
            These strategies aim to minimize taxation risk by hedging against
            potential losses. Examples of risk management strategies include
            insurance, diversification of investments, and financial
            derivatives.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Tax planning strategies:</h4>
          </li>
          <p>
            These strategies involve taking advantage of tax laws to minimize
            tax liabilities and maximize tax benefits.
          </p>
        </ul>
        <p tw="text-left">
          Overall, financial strategies help individuals and organizations make
          informed decisions about managing their accounts to achieve their
          long-term financial goals.
        </p>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <FinancialStategiesCp />
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default FinancialStrategies;
