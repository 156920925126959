import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import SliderImg from "assets/slider.jpg";

import Header, {
    NavLink,
    NavLinks,
    LogoLink,
    NavToggle,
    DesktopNavLinks,
} from "components/headers/Header.js";
import { Link } from "react-router-dom";
import Header2 from "./headers/Header2";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)
`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-primary-400`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-400`}
  }
`;
const Container = styled.div`
  ${tw`relative -mt-8 bg-center bg-cover`}
  background-image: url('${SliderImg}');
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-white px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-primary-400 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 lg:text-lg border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 2xl:mt-8 border-none bg-primary-400 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4  text-white font-bold rounded shadow transition duration-300 hocus:bg-primary-100 hocus:text-white focus:shadow-outline`;
const PrimaryLink = tw.button`lg:mx-0
  px-8 py-3 rounded bg-primary-400 text-white
  hocus:bg-primary-200 hocus:text-white focus:shadow-outline
  font-semibold border-none`;

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <Link className="smlscreen-mt" to="/">
        <NavLink>Home</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/about-us">
        <NavLink>About Us</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/default-services">
        <NavLink>Services</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/industry">
        <NavLink>Industry</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/contact-us">
        <NavLink>Contact Us</NavLink>
      </Link>
    </NavLinks>,
    <NavLinks>
      <Link to="/inquiry">
        <PrimaryLink>Make an Inquiry</PrimaryLink>
      </Link>
    </NavLinks>,
  ];

  return (
    <Container>
      {/* <OpacityOverlay /> */}
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        <div className="pt-4 pb-4">
          <Header2 />
        </div>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>Navigating Taxes </span>
              <br />
              <SlantedBackground className="slanted-design mt-4">
              Simplifying Your Life
              </SlantedBackground>
            </Heading>
            <Notification>
              Experience hassle-free tax and accounting services with <br /> our
              expert team in Perth, WA. Let us help you grow financially.
            </Notification>
            <Link to="/contact-us">
              <PrimaryAction>Get Started Today</PrimaryAction>
            </Link>
          </LeftColumn>
          <RightColumn></RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};