import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-primary-200 text-2xl  leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = null,
  heading = "List of Tax Advisory Services We Offer ",
}) => {
  const defaultCards = [
    {
      title: "International Taxes",
      description:
        "Fortune accounting and taxation provides comprehensive guidance on the complex tax laws and regulations for multinational corporations and individuals doing business across borders.",
    },
    {
      title: "Local Taxes",
      description:
        "Our local tax advisory service in Perth can help you navigate local tax laws and regulations, and ensure that you meet all your tax obligations while minimizing your tax liability.",
    },
    {
      title: "VAT Refunds",
      description:
        "Our VAT refunds service in Perth helps businesses reclaim the taxes they've paid on business expenses. Our team of experts ensures a streamlined and hassle-free process for maximum refunds.",
    },
    {
      title: "Digital Services Tax",
      description:
        "We offer digital services tax advisory to help businesses navigate the complexities of this new tax. Our expert team in Perth provides personalized solutions tailored to your specific needs.",
    },
    {
      title: "Personal Taxes",
      description:
        "We provide advice and assistance on personal taxes. From preparing tax returns to maximizing deductions, we strive to ensure our clients' tax obligations are fulfilled efficiently and accurately.",
    },
    {
      title: "Business Taxes",
      description:
        "With our expert advisory services, extensive knowledge and experience, we ensure your business stays compliant while minimizing tax liabilities and maximizing returns.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Subheading>Our Services</Subheading>
        <Heading>{heading}</Heading>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
