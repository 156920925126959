import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

//Indutries Icons

import QualifiedIcon from "assets/chooseusIcons/icon-01.svg";
import TradersIcon from "assets/industry/traders-01.svg";
import ForwardIcon from "assets/chooseusIcons/icon-06.svg";

const Container = tw.div`relative`;
const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-primary-500 text-center  p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 text-justify lg:text-center font-semibold text-secondary-100 text-base leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: QualifiedIcon,
      title: "Qualified Accountants",
      description:
        "Our team of highly qualified accountants are equipped with expertise of more than a decade and knowledge to provide exceptional accounting and taxation services.",
    },
    {
      imageSrc: TradersIcon,
      title: "Transparent",
      description:
        "We prioritize transparency in all our accounting and taxation services to ensure our clients are fully informed and confident in their accounting decisions.",
    },
    {
      imageSrc: ForwardIcon,
      title: "Forward thinking",
      description:
        "We pride ourselves on being forward-thinking in our approach to accounting and taxation services, constantly adapting to new technologies and industry trends to provide innovative solutions for our clients.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Subheading>Counting With Care</Subheading>
        <Heading>Supporting your Business</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">
                  <h4>{card.title || "Fully Secure"}</h4>
                </span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
