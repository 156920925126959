import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div `relative`;

const ThreeColumnContainer = styled.div `
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.h4`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-lg`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw` border-primary-500 text-center  p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = null,
  heading = "Types of business structures we provide",
  subheading = "Our Services",
  description = "At our firm, we understand that choosing the right business structure is crucial for the success and longevity of your business. That's why we provide expert advice and assistance in determining the best structure for your unique needs and goals. Our business structure services include:",
}) => {
  const defaultCards = [
    {
      title: "Consultation and analysis:",
      description:
        "We begin by conducting a thorough analysis of your business goals, operations, and legal requirements to help you choose the most appropriate structure.",
    },
    {
      title: "Sole trader:",
      description:
        "If you're a sole trader, we can help you understand the legal and tax implications of operating as an individual and provide advice on the most effective ways to structure your business.",
    },
    {
      title: "Partnership:",
      description:
        "For those who are considering starting a business with one or more partners, we can provide guidance on the different types of partnerships available and help you choose the right structure for your needs.",
    },
    {
      title: "Company formation: ",
      description:
        "Our team can assist you with the legal and administrative requirements of incorporating your business as a company, including registering for an Australian Business Number (ABN) and Tax File Number (TFN)",
    },
    {
      title: "Trust formation:",
      description:
        "We can also provide guidance on establishing a trust structure, which can be a useful way to protect assets and manage tax liabilities.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};