import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = ({ center, zoom }) => {
  return (
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'YOUR_GOOGLE_MAPS_API_KEY_HERE' }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.defaultProps = {
  center: {
    lat: 37.7749,
    lng: -122.4194
  },
  zoom: 11
};

export default GoogleMap;
