import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { Link } from "react-router-dom";
import defaultCardImage from "images/shield-icon.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

//Indutries Icons

import BusinessIcon from "assets/industry/business-01.svg";
import TradersIcon from "assets/industry/traders-01.svg";
import HospitalityIcon from "assets/industry/hospitality-01.svg";
import { Button } from "react-bootstrap";
const SeeAllBtn = tw(
  PrimaryButtonBase
)`bg-primary-400 text-white text-sm border-none sm:text-base sm:mt-8  sm:px-8 sm:py-4  text-primary-200 font-bold rounded shadow transition duration-300 hocus:bg-primary-200 hocus:text-white focus:shadow-outline`;

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw` border-primary-500 text-center  p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-base leading-loose`}
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: BusinessIcon,
      title: "Business",
      description:
        "We offer expert accounting and taxation services for businesses of all sizes, helping them to manage their accounts effectively and minimize their tax liabilities.",
    },
    {
      imageSrc: TradersIcon,
      title: "Traders",
      description:
        "Our accounting and taxation services cater to traders, ensuring they comply with tax laws while maximizing their profits. Let us handle the numbers, you focus on trading.",
    },

    {
      imageSrc: HospitalityIcon,
      title: "Hospitality",
      description:
        "Our accounting and taxation services are tailored to meet the unique needs of the hospitality industry, ensuring compliance, managing cash flow and maximizing profits.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Industries We <span tw="text-primary-600">Serve</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">
                  <h4>{card.title || "Fully Secure"}</h4>
                </span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <Link to="/industry">
          <SeeAllBtn className="text-center mt-5">See All</SeeAllBtn>
        </Link>
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
