import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { payroll } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const DefaultServicePage = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{payroll.title}</title>
        <meta name="description" content={payroll.description} />
        <link rel="canonical" href={payroll.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>PAYROLL SERVICES</Heading>
          <h4>
            Payroll service involves processing employee payments and taxes,{" "}
            <br /> including calculating wages, withholding taxes, and issuing
            paychecks or direct deposits.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              ACCOUNTING <br /> & PAYROLL
            </h2>
            <div className="vertical-line"></div>
            <p>
              We provide efficient and accurate payroll processing services to
              manage <br /> employee payments and compliance with government
              regulations.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Payroll services for small businesses
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Payroll service for small businesses involves processing employee
          payments and taxes. This includes calculating wages, withholding
          taxes, and issuing paychecks or direct deposits. It can be
          time-consuming and complicated, particularly for businesses with a
          limited number of employees or without dedicated HR staff. Outsourcing
          payroll to a professional service provider can alleviate this burden,
          ensuring accurate and timely payment processing and compliance with
          tax laws.
          <br />
          <br />
          By outsourcing payroll, small businesses can also avoid potential
          errors or penalties that could arise from incorrectly calculating
          taxes or missing filing deadlines. Additionally, it can free up time
          and resources that can be redirected towards other areas of the
          business. With reliable and efficient payroll services, small
          businesses can stay on top of their financial obligations and focus on
          growth and development.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Heading2 className="pt-3 pb-4 text-left">
            The benefits of Fortune Payroll Services
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Payroll services provide a range of benefits to businesses of all
            sizes. Firstly, it helps to ensure compliance with tax laws and
            regulations, avoiding penalties and fines that can occur from
            incorrect payroll calculations or filing errors. Additionally,
            outsourcing payroll can save businesses time and resources, allowing
            them to focus on their core operations and strategic goals. Payroll
            services also offer a higher level of accuracy and reliability than
            in-house payroll processing. Professional payroll providers have the
            necessary expertise and software to handle complex payroll
            calculations and tax deductions, reducing the risk of errors. This
            can provide peace of mind to business owners, who can rest assured
            that their employees will be paid accurately and on time.
            <br />
            <br />
            Another advantage of payroll services is their ability to provide
            additional HR and accounting support. Some payroll companies offer
            integrated HR solutions, such as employee onboarding and benefits
            administration, while others can provide accounting services such as
            bookkeeping and tax preparation. This can help small businesses to
            streamline their operations and reduce the need for multiple service
            providers. Overall, payroll services can help businesses to reduce
            costs, save time, and increase accuracy and compliance. With the
            increasing complexity of tax laws and regulations, outsourcing
            payroll can provide a cost-effective and efficient solution for
            businesses of all sizes.
          </div>
        </Container>
      </div>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">Our Services</Subheading>
        <Heading2 className="text-center">
          How Fortune prepares Payroll Accounting services
        </Heading2>
        <p tw="text-left">
          Payroll service involves the timely and accurate processing of
          employee payments, taxes, and other payroll-related tasks. At our
          company, we provide comprehensive payroll solutions to help businesses
          manage their employee payroll process more efficiently. Our payroll
          services include:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4> Employee data management:</h4>
          </li>
          <p>
            We help businesses manage employee data, such as personal
            information, tax information, and payment details, to ensure
            accurate and timely payroll processing.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Payroll processing:</h4>
          </li>
          <p>
            We calculate employee wages, including deductions and withholdings,
            and process payroll on a regular basis, ensuring timely and accurate
            payments.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Tax filing and compliance:</h4>
          </li>
          <p>
            We file taxes on behalf of our clients and ensure that they are in
            compliance with payroll tax regulations and reporting requirements.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Time and attendance management:</h4>
          </li>
          <p>
            We provide solutions to help businesses track employee hours and
            attendance, ensuring accurate payroll processing.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Employee self-service:</h4>
          </li>
          <p>
            We offer employee self-service portals that allow employees to view
            their pay stubs, update their personal information, and access other
            payroll-related information.
          </p>
        </ul>
        <p tw="text-left">
          Our payroll service is designed to help small businesses streamline
          their payroll process, minimize errors, and stay compliant with
          payroll tax regulations. With our payroll services, businesses can
          focus on their core operations while leaving the complex payroll tasks
          to our experienced professionals.
        </p>
      </Container>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default DefaultServicePage;
