import React from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import { industry } from "MetaTags";
import { MetaTags } from "react-meta-tags";
import { css } from "styled-components/macro";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import breadImg from "assets/industry-brdcrms.jpg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import Header2 from "components/headers/Header2";

const Industry = () => {
  const Heading = tw.h1`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.span`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{industry.title}</title>
        <meta name="description" content={industry.description} />
        <link rel="canonical" href={industry.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>
            <h1>INDUSTRY</h1>
          </Heading>
          <h4>
            We provide top-notch accounting services for all business sizes and
            sectors. <br /> Our team of experts offers tailored solutions to
            meet your unique needs.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              RANGE OF <br /> INDUSTRY
            </h2>
            <div className="vertical-line"></div>
            <p>
              Accounting is an essential need for every industry, enabling
              businesses to manage finances, <br /> make informed decisions, and
              maintain financial stability
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading className="pt-3 pb-4 text-center">
          Fortune Accounts provide services to different Industries
        </Heading>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          At Fortune accounting and taxation service, we have experience in
          serving clients from various industries such as healthcare,
          construction, retail, hospitality, and more. Our team of qualified
          accountants understands the specific challenges and requirements of
          each industry, and we work closely with our clients to provide
          tailored solutions to meet their unique needs. We are committed to
          staying up-to-date with industry-specific regulations and standards,
          ensuring our clients are always in compliance.
          <br />
          With our diverse industry experience, we are confident in our ability
          to provide the highest level of service to any client, regardless of
          their industry.
        </div>
      </Container>
      <Container>
        <AllIndustriesCp />
      </Container>
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
    </div>
  );
};

export default Industry;
