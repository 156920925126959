const defaultTitle =
    "Professional Accounting and Taxation Services in Perth, WA";
const defaultDescription =
    "Get the most accurate and reliable accounting and taxation services in Perth, WA. Fortune Accounts offers a wide range of solutions to meet your needs.";
const siteUrl = "https://fortuneaccounts.com.au/";

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl,
};
export const aboutUs = {
    title: "Best Accountant and Tax Advisor in Perth, WA ",
    description: "Are you looking for an accounting and taxation services company in Perth? Fortune Accounts can help you with your queries.",
    canonical: siteUrl + "about-us",
};

export const advisory = {
    title: "Accounting & Tax Advisory services in Perth, WA",
    description: "We are professional tax advisors with 12 years of experience. We can ensure you get the right Tax Advisory and make the most of your planning.",
    canonical: siteUrl + "advisory-service",
};
export const bookkeeping = {
    title: "Bookkeeping Accounting | Best Bookkeeping Services in Perth",
    description: "We are a bookkeeping agency with tax specialists. We offer the best bookkeeping service for all types of businesses, giving you time to focus on your business. ",
    canonical: siteUrl + "bookkeeping-service",
};
export const bas = {
    title: "Business Activity Statement (BAS) services in Perth, WA",
    description: "Looking for Business Activity Statement (BAS) services in Perth? Fortune Accounts offers a wide range of solutions to meet your needs.",
    canonical: siteUrl + "bas-service",
};
export const businessStructure = {
    title: "Business Structures Set-Up & Advice Services in Perth, WA",
    description: "We take the time to understand your business, industry and economic challenges to develop great business structures that build company growth.",
    canonical: siteUrl + "business-structure-service",
};
export const ato = {
    title: "Australian Taxation Office (ATO) business services in Perth, WA",
    description: "As the best consulting firms, we can deal with the ATO to provide more clarification and information regarding any tax issues and the implications of any ATO rules.",
    canonical: siteUrl + "ato-service",
};
export const financialReports = {
    title: "Financial Reports Service Provider in Perth, WA",
    description: "We compile financial reports such as profit & loss statements, balance sheets, trial balances, depreciation schedules and cash flow statements.",
    canonical: siteUrl + "financial-reports-service",
};
export const financialStatements = {
    title: "Financial Statements Service Provider in Perth, WA",
    description: "We prepare financial statements in accounting for individuals, sole traders, partnerships, trusts, companionships and self-managed superannuation funds.",
    canonical: siteUrl + "financial-statements-service",
};
export const financialStrategies = {
    title: "Financial Strategies for Business in Perth, WA ",
    description: "Looking for a Financial Strategy? Fortune Accounts helps you improve the financial strategies of your business to build company growth.",
    canonical: siteUrl + "finnancial-strategies-service",
};
export const gstpayment = {
    title: "GST Payments and Refunds Services in Perth, WA",
    description: "Fortune Accounts provides Goods and Services Tax (GST) Payment and Refund services for corporate and non-corporate clients.",
    canonical: siteUrl + "gstpayment-service",
};
export const ias = {
    title: "Instalment Activity Statement (IAS) services in Perth, WA",
    description: "Fortune Accounts helps you generate monthly, quarterly and yearly Instalment Activity Statements (IAS) for your business.",
    canonical: siteUrl + "ias-service",
};
export const payroll = {
    title: "Payroll Accounting Service Provider in Perth, WA",
    description: "We are accounting specialists, and we offer accounting and payroll services for a variety of our firm's clients, including businesses, individuals and other entities.",
    canonical: siteUrl + "payroll-service",
};
export const prepationSchedules = {
    title: "Rental, Capital Gain, Depreciation & Motor Vehicle schedule providers in Perth ",
    description: "We prepare Rental Schedules, Capital Gain Schedules, Depreciation Schedules, Motor Vehicle Schedules etc. Fortune Accounts offers a wide range of solutions to meet your needs. ",
    canonical: siteUrl + "preparation-schedules-service",
};
export const registration = {
    title: "TFN, ABN, GST, PAYG & Business Name Registration Services in Perth, WA",
    description: "We provide applications for registration for TFN, ABN, GST, PAYG and Business name registration. Fortune Accounts can help you with your queries.",
    canonical: siteUrl + "registration-service",
};
export const taxplanning = {
    title: "Tax Planning Service Provider in Perth, WA",
    description: "Looking for Tax Planning services in Perth? Fortune Accounts Tax Specialists can help you with your tax accounting services.  ",
    canonical: siteUrl + "taxplanning-service",
};
export const taxreturns = {
    title: "Tax Returns service | Tax Return Accountant in Perth",
    description: "We prepare Tax Returns for individuals, sole traders, partnerships, trusts, companionships and self-managed superannuation funds.",
    canonical: siteUrl + "taxreturns-service",
};
export const services = {
    title: "",
    description: "",
    canonical: siteUrl + "services",
};
export const industry = {
    title: "Industries we service | Fortune Accounts | Accountant and Tax Advisor",
    description: "Fortune Accounts provides specialized accounting services in many industries, like business, sole traders, healthcare & many more. Contact us today for a consultation!",
    canonical: siteUrl + "industry",
};
export const contactUs = {
    title: "Contact us | Accountant and Tax Advisor | Fortune Accounts ",
    description: "Contact us today for accurate accounting & taxation services. Fortune Accounts offers a wide range of solutions to meet your needs.",
    canonical: siteUrl + "contact-us",
};

export const privacyPolicy = {
    title: "Privacy Policy | Fortune Accounts",
    description: "Privacy Policy by Fortune Accounting. Trusted accounting and taxation service provider in Perth, WA.",
    canonical: siteUrl + "privacy-policy",
};
export const inquiry = {
    title: "Make an Inquiry Today | Fortune Accounts | Best Tax Consulting Firms",
    description: "Make an Inquiry today for any accounting and taxation services. Fortune Accounts offers a wide range of solutions to meet your needs.",
    canonical: siteUrl + "inquiry",
};

export const thankYou = {
    title: "Thank you | Fortune Accounts | Accountant and Tax Advisor",
    description: "Thank You | Fortune Accounts",
    canonical: siteUrl + "thank-you",
};