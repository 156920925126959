import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import BenefitsVector from "assets/vectors/benefits.png";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:pr-12 lg:pr-16 md:order-first`
    : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-justify md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.h4`mt-4 text-xl font-bold text-primary-300  sm:text-justify`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-secondary-100  sm:text-justify`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-primary-600 hover:bg-primary-400 text-primary-500 hover:text-primary-700 focus:outline-none border-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  imageSrc = BenefitsVector,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "About",
  heading = "Benefits of Accounting & Taxation Services.",
  // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaadipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
  description = "Accounting and taxation services provide accurate financial reporting, tax savings, and expert advice, helping businesses comply with regulations, reduce risks, and achieve financial success. ",
  textOnLeft = false,
  testimonials = [
    {
      heading: "Save Time & Money",
      quote:
        "Accounting and tax procedures are typically lengthy and complex. Using accounting services can make it easier to manage a company’s accounts. Taxation services ensure that clients do not struggle or stay current on all laws. Accounting service specialists can help a company save time on operations and documentation. Accounting processes can be shortened with the assistance of knowledgeable personnel.Taxation necessitates knowledge of government laws and legal procedures. A simple error can have a significant impact on an organization. A taxation expert can effortlessly manage all of these complicated procedures.",
    },
    {
      heading: "Access Vital Information",
      quote:
        "Accounting services provide organizational oversight. The documentation streamlines the decision-making process in critical situations. The data is generated based on a company’s large and complex mechanism. The reports assist in gaining insight into departments. It provides an overview of the larger picture. These documents contain crucial information about the company’s growth, profitability, liquidity, and flexibility. It provides information that results from the functioning of a company’s internal system.",
    },
    {
      heading: "Increase Productivity",
      quote:
        "The operation of a large corporation necessitates long-term planning. Because the risks are high, a management plan must be developed ahead of time. Deliverables are used to plan and organize each project’s processes or operations. It guides in the creation of a road map for employees to follow in their tasks. Planning improves understanding of problems, increases speed, and improves problem-solving.As a result, the company’s overall productivity is high.",
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image
              src={imageSrc}
              alt="Affordable Accounting | Small Business Accountant in Perth, WA | Fortune Acccounts"
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  {/* <StarsContainer>
                    {Array.from({ length: testimonial.stars }).map(
                      (_, indexIcon) => (
                        <StarIcon key={indexIcon} />
                      )
                    )}
                  </StarsContainer> */}
                  <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                  <Quote tw="text-justify">{testimonial.quote}</Quote>
                  <CustomerInfoAndControlsContainer>
                    <CustomerInfo>
                      <CustomerTextInfo>
                        <CustomerName>{testimonial.customerName}</CustomerName>
                        <CustomerTitle>
                          {testimonial.customerTitle}
                        </CustomerTitle>
                      </CustomerTextInfo>
                    </CustomerInfo>
                    <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
