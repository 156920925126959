import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { ato } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const ATOService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{ato.title}</title>
        <meta name="description" content={ato.description} />
        <link rel="canonical" href={ato.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>
            Australian Taxation Office <br /> (ATO) Services
          </Heading>
          <h4>
            Dealing with ATO refers to the process of interacting with the
            <br />
            Australian Taxation Office (ATO) regarding various tax-related
            matters
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              ATO <br /> ADVISOR
            </h2>
            <div className="vertical-line"></div>
            <p>
              We can help you navigate through tax obligations, compliance
              requirements, and any other issues related to the ATO. <br /> We
              provide a range of services including tax return lodgement, audit
              support, dispute resolution, and more.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          ATO Business Services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Dealing with ATO service refers to the assistance provided by tax
          professionals in managing various tax obligations and interactions
          with the Australian Taxation Office (ATO). It involves a range of
          activities, such as lodging tax returns, responding to ATO queries and
          audits, negotiating payment arrangements, applying for tax
          concessions, and resolving disputes.
          <br />
          <br />
          When running a business, it is essential to comply with the tax laws
          and regulations, and dealing with the ATO can be a daunting task for
          many business owners. Engaging a qualified tax professional to manage
          the dealings with the ATO can ensure compliance and minimize the risk
          of penalties or legal actions. The tax professionals can provide
          practical advice on tax matters, communicate with the ATO on behalf of
          the clients, and ensure that all the tax obligations are met in a
          timely and accurate manner.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            How Fortune deals with the ATO on your tax issues and ATO rules
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Dealing with the Australian Taxation Office (ATO) can be a daunting
            task for businesses. We provide ATO services in Perth, WA to assist
            with all ATO-related matters. Our team of experts can help you with
            tax compliance, disputes, audits, and any other ATO-related issues
            that you may encounter.
            <br />
            <br />
            Our ATO services include assisting businesses with tax returns,
            business activity statements, and installment activity statements.
            We can also provide guidance on tax planning and financial
            strategies to help minimize tax liabilities and maximize business
            profits. Our team is well-versed in dealing with the ATO and can
            help businesses stay compliant with tax laws and regulations.
            <br />
            <br />
            With our ATO services, businesses can focus on their core activities
            while leaving the complexities of tax compliance to us. We
            understand that every business is unique, and we strive to provide
            personalized solutions to meet their specific needs. Contact us
            today to see how we can help your business with ATO-related matters
            in Perth, WA.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default ATOService;
