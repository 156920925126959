import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.h4`w-full text-center mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;
const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw` border-primary-500 text-center  p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-base leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = null,
  heading = "How Fortune prepares Financial Statements in accounting",
}) => {
  const defaultCards = [
    {
      title: "Accurate Reporting:",
      description:
        "Our team of experienced accountants ensures that the financial statements are prepared accurately and in compliance with relevant accounting standards.",
    },
    {
      title: "Timely Preparation:",
      description:
        "We understand the importance of timely reporting and work efficiently to prepare financial statements within the required timeframe.",
    },
    {
      title: "Customizable Reports:",
      description:
        "We provide customizable financial reports that can be tailored to meet the specific needs of your business.",
    },
    {
      title: "Analytical Insights:",
      description:
        "Our financial statements include analytical insights that help you identify trends and make informed business decisions.",
    },
    {
      title: "Financial Compliance:",
      description:
        "We ensure that your financial statements are prepared in compliance with regulatory requirements and standards.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Subheading>Our Services</Subheading>
        <Heading>{heading}</Heading>
        <Description>
          Financial statement preparation is a crucial aspect of accounting that
          helps businesses keep track of their financial performance. It
          involves creating reports that provide a comprehensive overview of a
          company's financial health, including its assets, liabilities,
          revenues, expenses, and cash flows. Here are some points about our
          financial statement preparation service:
        </Description>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        <Description>
          By availing of our financial statement preparation service, businesses
          can benefit from accurate and timely financial reporting, gain
          valuable insights, and ensure compliance with relevant regulations.
        </Description>
      </ThreeColumnContainer>
    </Container>
  );
};
