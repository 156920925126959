import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div `relative`;

const ThreeColumnContainer = styled.div `
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.h4`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-lg`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw` border-primary-500 text-center  p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-base leading-loose`}
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  cards = null,
  heading = "Benefits of having a Financial Strategy",
  subheading = "Our Services",
  description = "A financial strategy can provide numerous benefits to both individuals and businesses. Here are some key advantages:",
}) => {
  const defaultCards = [
    {
      title: "Clarity and focus",
      description:
        "A financial strategy provides a clear roadmap for achieving your financial goals and helps you stay focused on them.",
    },
    {
      title: "Better decision-making",
      description:
        "With a solid financial strategy in place, you can make better financial decisions that align with your goals and values.",
    },
    {
      title: "Improved cash flow management:",
      description:
        "A financial strategy can help you optimize your cash flow, which is critical for both personal and business finances",
    },
    {
      title: "Risk management",
      description:
        "A financial strategy can help you identify and mitigate potential risks, such as market fluctuations, unexpected expenses, or changes in regulations",
    },
    {
      title: "Long-term sustainability",
      description:
        "A financial strategy helps you plan for the long-term, ensuring that you can sustain your financial well-being over time.",
    },
    {
      title: "Peace of mind",
      description:
        "A well-crafted financial strategy can provide a sense of security and peace of mind, knowing that you have a plan in place to achieve your financial goals.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};