import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { MetaTags } from "react-meta-tags";
import { businessStructure } from "MetaTags";
import { useEffect } from "react";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import FinancialStategiesCp from "components/FinancialStategiesCp";
import BusinessStructureCp from "components/BusinessStructureCp";
import SoftwareSection from "components/SoftwareSection";

const BusinessStructure = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{businessStructure.title}</title>
        <meta name="description" content={businessStructure.description} />
        <link rel="canonical" href={businessStructure.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>BUSINESS STRUCTURES</Heading>
          <h4>
            Business structure refers to the legal and organizational framework
            of a business entity. <br /> It includes factors such as the type of
            entity, ownership structure, liability, taxation, and governance
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>BUSINESS ADVISOR</h2>
            <div className="vertical-line"></div>
            <p>
              Our business structure advice takes into account your business
              goals, <br /> legal requirements, and tax implications to
              determine the most effective structure.
            </p>
          </div>
        </Container>
      </div>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">Our Services</Subheading>
        <Heading2 className="text-center">
          Business structure services for small business
        </Heading2>
        <p tw="text-left">
          Setting up a business structure is a crucial step for small
          businesses. It not only impacts the legal, financial, and operational
          aspects of a business but also determines the owner's personal
          liability and tax obligations. Here are some common business
          structures:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Investment strategies:</h4>
          </li>
          <p>
            These are strategies that involve investing money in various
            financial instruments such as stocks, bonds, mutual funds, and real
            estate to generate returns and build wealth.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Sole proprietorship:</h4>
          </li>
          <p>
            Owned and operated by a single person, who is personally liable for
            the business's debts and obligations.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Risk management strategies:</h4>
          </li>
          <p>
            These strategies aim to minimize financial risk by hedging against
            potential losses. Examples of risk management strategies include
            insurance, diversification of investments, and financial
            derivatives.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Tax planning strategies:</h4>
          </li>
          <p>
            These strategies involve taking advantage of tax laws to minimize
            tax liabilities and maximize tax benefits.
          </p>
        </ul>
        <p tw="text-left">
          Overall, financial strategies help individuals and organizations make
          informed decisions about managing their accounts to achieve their
          long-term financial goals.
        </p>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <BusinessStructureCp />
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default BusinessStructure;
