import React from "react";
import tw from "twin.macro";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { taxreturns } from "MetaTags";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const TaxReturns = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{taxreturns.title}</title>
        <meta name="description" content={taxreturns.description} />
        <link rel="canonical" href={taxreturns.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>TAX RETURNS</Heading>
          <h4>
            A tax return reports income, deductions, and tax liabilities to the
            government, <br /> and is used to calculate taxes owed or claim a
            refund.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              TAX RETURNS <br /> ACCOUNTANT
            </h2>
            <div className="vertical-line"></div>
            <p>
              We assist with tax return preparation and lodgment, <br />{" "}
              ensuring compliance and maximizing tax deductions.
            </p>
          </div>
        </Container>
      </div>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Heading2 className="text-center">
          Tax Returns preparation services
        </Heading2>
        <p tw="text-left">
          Our tax returns service in WA helps individuals and businesses in the
          region to comply with tax laws and regulations while minimizing tax
          liabilities. We assist in the preparation and lodgment of tax returns
          for various entities, including sole traders, partnerships, companies,
          and trusts. Our team stays up-to-date with the latest tax laws and
          regulations to provide accurate and timely advice to our clients. Some
          key areas we cover in our tax returns service include:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Income Tax</h4>
          </li>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Capital Gains Tax</h4>
          </li>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Goods and Services Tax</h4>
          </li>
        </ul>
        <p tw="text-left">
          We also offer advice on tax planning strategies to help clients make
          informed decisions and maximize tax savings.
        </p>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            How Fortune prepares Tax Returns in Accounting
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Tax return service involves preparing and lodging income tax returns
            to the Australian Taxation Office (ATO) on behalf of individuals and
            businesses. It ensures that all tax obligations are met accurately
            and on time. Tax returns take into account income earned from
            various sources, deductions claimed, and applicable tax offsets.
            Engaging professional tax return services can save time and effort,
            as they are well-versed in the complex tax laws and regulations. It
            can also help in minimizing tax liabilities and maximizing tax
            refunds. Additionally, outsourcing tax returns can provide peace of
            mind and ensure compliance with all relevant taxation laws.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default TaxReturns;
