import React from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { thankYou } from "MetaTags";
import styled from "styled-components";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// Icons
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as LinkedIcon } from "assets/icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";

import breadImg from "assets/getintouch-brdcrms.jpg";
import Header2 from "components/headers/Header2";

const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:ml-12 lg:ml-16 md:order-last`
    : tw`md:mr-12 lg:mr-16 md:order-first`,
]);
const HighlightedText = tw.span`text-primary-600`;
const myStyle = {
  background: "#3973B6",
  marginBottom: "20rem",
};

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw.h1`mt-4 font-black text-white text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>{thankYou.title}</title>
        <meta name="description" content={thankYou.description} />
        <link rel="canonical" href={thankYou.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs text-center relative" style={myStyle}>
        <div className="container">
          <Heading>
            <h1 className="text-center">
              THANK YOU FOR YOUR INTEREST <br /> IN FORTUNE ACCOUNTS
            </h1>
          </Heading>
        </div>
        <div className="white-box text-center">
          <h4 className="text-black font-semibold p-4">
            Don’t Forget to follow us on social media !
          </h4>
          <ol>
            <li>
              <Link
                to="https://www.facebook.com/profile.php?id=100092499608004"
                target="_blank"
              >
                <FacebookIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    color: "#3973B6",
                  }}
                />
              </Link>
            </li>

            {/* <li>
              <Link to="https://www.instagram.com/" target="_blank">
                <InstagramIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    color: "#3973B6",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link to="https://www.twitter.com/" target="_blank">
                <TwitterIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    color: "#3973B6",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/" target="_blank">
                <LinkedIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    color: "#3973B6",
                  }}
                />
              </Link>
            </li> */}
          </ol>
        </div>
      </section>
      <div>
        <p className="text-center">
          Click to go to <Link to="/">Homepage</Link>{" "}
        </p>
      </div>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
    </>
  );
};
