import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { registration } from "MetaTags";
import { css } from "styled-components/macro";
import { useEffect } from "react";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import RegistrationServiceCp from "components/FinancialStatementsCp";
import SoftwareSection from "components/SoftwareSection";

const RegistrationService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{registration.title}</title>
        <meta name="description" content={registration.description} />
        <link rel="canonical" href={registration.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>BUSINESS REGISTRATION</Heading>
          <h4>
            Registration service refers to the process of obtaining various
            licenses, permits, <br /> and certifications required to operate a
            business legally.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              BUSINESS <br /> ADVISORY ACCOUNTANT
            </h2>
            <div className="vertical-line"></div>
            <p>
              We offer comprehensive tax registration services, including TFN,
              ABN, GST, <br /> and PAYG, to ensure compliance with Australian
              tax laws.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          What is registrations service ?
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Registration service refers to the process of obtaining various
          licenses, permits, and certifications required to operate a business
          legally. This may include registering for tax purposes, obtaining
          business licenses, permits, and certifications from relevant
          regulatory bodies. Registration service also includes ensuring
          compliance with relevant regulations and laws, such as environmental
          laws and labor laws.
          <br />
          <br />
          Failure to register for the necessary licenses and permits can result
          in significant fines and legal complications. Therefore, it is
          essential to seek professional registration services to ensure
          compliance and avoid legal issues. A registration service provider can
          assist in completing the necessary paperwork, submitting applications,
          and ensuring timely renewals of licenses and permits.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <RegistrationServiceCp />
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default RegistrationService;
