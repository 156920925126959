import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import ExpertsIcon from "assets/chooseusIcons/icon-01.svg";
import FinanceIcon from "assets/chooseusIcons/icon-02.svg";
import ProfessionalIcon from "assets/chooseusIcons/icon-03.svg";
import GuaranteeIcon from "assets/chooseusIcons/icon-04.svg";
import ValuableIcon from "assets/chooseusIcons/icon-05.svg";
import AccountinSpecialistIcon from "assets/chooseusIcons/icon-06.svg";

const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col w-full md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
// const TextColumn = styled(Column)((props) => [
//   tw`md:w-6/12 mt-16 md:mt-0`,
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`,
// ]);
// const OneColumn = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

// const TextColumn = styled(OneColumn)((props) => [
//   tw`md:w-6/12 mt-16 md:mt-0`,
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`,
// ]);
const OneColumn = tw.div`w-full max-w-screen-xl mx-auto py-10 md:py-12`;
const myStyle = {
  width: "35px",
};

const TextColumn = styled(OneColumn)((props) => [
  tw`mx-auto`,
  props.textOnLeft ? tw`lg:w-11/12 lg:pr-12` : tw`lg:w-11/12 lg:pl-12`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-8 text-justify lg:text-left  md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mx-auto md:mx-0 flex flex-col lg:flex-col max-w-lg lg:max-w-none`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${(props) => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`,
  ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const FeatureHeading = tw.h4`ml-3 font-bold text-primary-300 text-xl`;

const FeatureDescription = tw.div`mt-4 lg:ml-12 text-justify lg:text-left md:text-left text-gray-600 leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-12 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  subheading = "Affordable Accounting",
  heading = <>Why Choose Us</>,
  description = "Choosing our taxation service means partnering with a team that has over 12 years of expertise in providing accounting services in Australia. Our founder is a highly experienced accountant who graduated with honors from Curtin University of Technology in Perth, Western Australia. We pride ourselves on our ability to offer tailored solutions to meet our clients' unique needs. Our specialists have in-depth knowledge of tax laws and their applications, and we help our clients reduce tax obligations or increase tax refunds by making legitimate deductions. Our commitment to client satisfaction and attention to detail sets us apart from the competition.",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = false,
  imageBorder = false,
  imageShadow = false,
  showDecoratorBlob = false,
  textOnLeft = true,
  features = null,
  iconRoundedFull = false,
  iconFilled = false,
  iconContainerCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      imgsrc: GuaranteeIcon,
      title: "Guaranteed Client Satisfaction",
      description:
        "Our customers are our top priority. We endeavor to provide best practices to our client’s concerns.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`,
    },
    {
      imgsrc: FinanceIcon,
      title: "Sound Accounting Solutions",
      description:
        "We offer the best solutions to any of your accounting issues. Our solutions never need to be rectified.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
    {
      imgsrc: ProfessionalIcon,
      title: "Professional, Proactive Services",
      description:
        "Our services consistently satisfy our clients. We have qualified professionals who can give the best accounting and tax services in Australia.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
    {
      imgsrc: ExpertsIcon,
      title: "A Diligent team of Experts",
      description:
        "Fortune Accounting and Taxation Service has highly qualified and educated staff in accounting and tax services. Our personnel are diligent about their work.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
    {
      imgsrc: AccountinSpecialistIcon,
      title: "Accounting Specialists",
      description:
        "For accounting services, our company has the most qualified accounting specialists.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
    {
      imgsrc: ValuableIcon,
      title: "Valuable Advice",
      description:
        "Our personnel work hard to stay up to date on new laws in order to provide quality advice to our clients.",
      iconContainerCss: tw`bg-red-300 text-red-800`,
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <OneColumn>
        {/* <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn> */}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureHeadingContainer className="smlscreen-flex">
                    <img src={feature.imgsrc} style={myStyle} alt="" />
                    <FeatureHeading>{feature.title}</FeatureHeading>
                  </FeatureHeadingContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </Feature>
              ))}
            </Features>
          </TextContent>
        </TextColumn>
      </OneColumn>
    </Container>
  );
};
