/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import { URL } from "Config";
import logo from "logo-foooter2.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { Alert, Col, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { css } from "styled-components/macro"; //eslint-disable-line
// Icons
import PhoneIcon from "assets/icons/phone-contact-01.svg";
import MailIcon from "assets/icons/mail-contact-01.svg";
import TimingIcon from "assets/icons/timing-contact-01.svg";
import LocationIcon from "assets/icons/location-contact-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import ContactUsImg from "assets/get-in-touch.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:ml-12 lg:ml-16 md:order-last`
    : tw`md:mr-12 lg:mr-16 md:order-first`,
]);

const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-auto`;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left `;
const Heading = tw(
  SectionHeading
)`mt-8 font-black text-left text-white text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-xl mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(
  PrimaryButtonBase
)`bg-white text-sm sm:text-base sm:mt-8 text-center  sm:px-8 sm:py-4  text-primary-200 font-bold rounded shadow transition duration-300 hocus:bg-primary-400 hocus:text-white border-none focus:shadow-outline`;

// eslint-disable-next-line import/no-anonymous-default-export
const to_email = "info@fortuneaccounts.com.au";
const cc = 'arkayappsseo@gmail.com'

const title = "Website Inquiry";
const subject = "Contact Form - Fortune";

export default ({
  subheading = (
    <>
      <span tw="text-gray-200">Contact Us</span>
    </>
  ),
  heading = (
    <>
      <Heading>
        Feel free to Get in Touch
        <wbr /> With Us
      </Heading>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send Message",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sundrop Solar</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }

        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            margin: 10px 0;
        }

        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:150px;
            height:auto;
        }

        .text-center {
            text-align: center !important;
        }

        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }

        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }

        .card-header {
            background: #136BCE;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }

        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }

        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #136BCE;
        }

        .card-body p strong {
            color: #333;
        }

        .card-body p strong.heading {
            color: #136BCE;
        }

        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>

<body>
    <div class="content">
        <div class="text-center">
            <img src="https://fortuneaccounts.com.au/logo.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received Message From Contact Form !</p>
        </div>
        <div class="card-body">
            <p><strong>Name : </strong>${name}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Mobile No: </strong>${mobile}</p>
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 Fortune Accounting & Taxation Services</p>
        </div>
    </div>
</body>

</html>`;
  const mailError = <p>{error}</p>;

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    setError(null);
  };
  const handleEmail = (e) => {
    var value = e.target.value;
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMobile = (value) => {
    setMobile(value);
    setError(null);
  };
  const handleMessage = (e) => {
    const value = e.target.value;
    setMessage(value);
    setError(null);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (name === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
    } else if (email === null) {
      setError(<Alert variant={"danger"}>Email Required</Alert>);
    } else if (mobile === null) {
      setError(<Alert variant={"danger"}>Phone Required</Alert>);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
    } else {
      setDisabled(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("to_email", to_email);
      formData.append("cc", cc);
      formData.append("template", template);
      formData.append("subject", subject);
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const url = URL + "/send-mail";
      axios
        .post(url, formData, headerConfig)
        .then((response) => {
          console.log(response);
          document.getElementById("contact-us").reset();
          setDisabled(false);
          navigate("/thank-you");
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response) {
            console.log(error.response);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{error.message}</Alert>);
          setDisabled(false);
        });
    }
  };
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <div className="img-contact">
            <img
              src={ContactUsImg}
              alt="Small Business Accountant | Tax and Accounting Services | Fortune Accounts"
            />
            {/* <div className="white-overlay"></div> */}
            <div className="img-content contact-info white-overlay">
              <h4>
                Let us handle the numbers so you can focus on growing your
                business.
              </h4>
              <div className="icon mt-5">
                <img
                  src={LocationIcon}
                  style={{ width: "22px", marginRight: "25px" }}
                  alt=""
                />
                <p>24 Vivid Chase, Dayton WA 6055</p>
              </div>

              <div className="icon mt-3">
                <img
                  src={PhoneIcon}
                  style={{ width: "22px", marginRight: "25px" }}
                  alt=""
                />
                <p>
                  <a href="tel:+61 422 456 064">+61 422 456 064</a>
                </p>
              </div>

              <div className="icon mt-3">
                <img
                  src={MailIcon}
                  style={{ width: "22px", marginRight: "25px" }}
                  alt=""
                />
                <p>
                  <a href="mailto:info@fortuneaccounts.com.au">
                    info@fortuneaccounts.com.au
                  </a>
                </p>
              </div>
              <div className="icon mt-3">
                <img
                  src={TimingIcon}
                  style={{ width: "22px", marginRight: "25px" }}
                  alt=""
                />
                <p>
                  Open Hours <br />
                  Monday - Friday <br /> 09:00 AM - 05:30 PM
                </p>
              </div>
            </div>
          </div>

          {/* <Image
            imageSrc={ContactUsImg}
            alt="Small Business Accountant | Tax and Accounting Services | Fortune Accounts"
          /> */}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {/* {description && <Description>{description}</Description>} */}
            <div className="align-center bg-section-dark-blue">
              <Form
                // ref={form}
                className="contact-form px-0 py-0"
                id="contact-us"
                onSubmit={handleFormSubmit}
              >
                <Row>
                  <Col className="mt-0" md={6}>
                    <input
                      type="text"
                      name="name"
                      required
                      className="form-control"
                      value={name}
                      minLength="3"
                      maxLength="50"
                      inputProps={{ minLength: 3, maxLength: 50 }}
                      onChange={handleName}
                      placeholder="Name"
                    />
                  </Col>
                  <Col className="mt-0" md={6}>
                    <input
                      name="email"
                      type="email"
                      required
                      className="form-control"
                      value={email}
                      minLength="3"
                      maxLength="50"
                      onChange={handleEmail}
                      placeholder="Email"
                    />
                  </Col>
                  <Col md={6}>
                    <PhoneInput
                      country={"au"}
                      onChange={handleMobile}
                      inputProps={{
                        minLength: 3,
                        maxLength: 18,
                        required: true,
                      }}
                    />
                  </Col>
                  <Col className="" md={12}>
                    <textarea
                      name="message"
                      message="message"
                      rows="4"
                      placeholder="Message*"
                      className="form-control"
                      required
                      inputProps={{ minLength: 3, maxLength: 200 }}
                      onChange={handleMessage}
                    ></textarea>
                  </Col>
                  <Col className="" md={12}>
                    <SubmitButton type="submit">
                      {submitButtonText}
                    </SubmitButton>
                    <br />
                    <br />
                  </Col>
                  {mailError}
                </Row>
              </Form>
            </div>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
