// Core
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import tw from "twin.macro";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../logo.png";
// BootStrap
import { Container, Col, Row, Navbar, Nav, NavDropdown } from "react-bootstrap";
// Library

import {
  Collapse,
  Drawer,
  Divider,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// Media

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export const NavLink = tw.a` lg:text-sm lg:mx-6 lg:my-0 text-sm
  font-semibold tracking-wide transition duration-300
  pb-1  border-transparent hover:border-primary-500 hocus:text-primary-200
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-400 text-white
  hocus:bg-primary-200 hocus:text-white focus:shadow-outline
  border-b-0
`;

const Header2 = () => {
  let location = useLocation().pathname;
  const classes = useStyles();
  const [openCompany, setOpenCompany] = useState(false);
  const [openServices, setOpenServices] = useState(true);
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlPortfolio, setUrlPortfolio] = useState("");
  const [urlBlogs, setUrlBlogs] = useState("");

  useEffect(() => {
    checkURL();
    // eslint-disable-next-line
  }, []);

  const checkURL = () => {
    if (location === "/") {
      setUrlHomepage("active");
    }
    if (location === "/our-work/") {
      setUrlPortfolio("active");
    }
    if (location === "/blog") {
      setUrlBlogs("active");
    }
  };

  const handleCompanyClick = () => {
    setOpenCompany(!openCompany);
  };

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="header sticky">
      <Container>
        <Navbar expand="xl" className="pt-4 pb-3">
          {["left"].map((anchor) => (
            <div>
              <Link to="/">
                <img
                  src={logo}
                  alt="Fortune Accounts Logo"
                  style={{ width: "230px" }}
                  className="mobile-view"
                />
              </Link>
            </div>
          ))}

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Link className="smlscreen-nav" to="/">
                <NavLink href="/">Home</NavLink>
              </Link>
              <Link className="smlscreen-nav" to="/about-us">
                <NavLink href="/about-us">About Us</NavLink>
              </Link>
              <NavLink className="smlscreen-nav">
                <NavDropdown
                  title="Services"
                  className="text pixeltop-3"
                  id="basic-nav-dropdown"
                >
                  <Link to="/advisory-service">
                    <NavDropdown.Item href="/services">
                      Advisory Services
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/bookkeeping-service">
                    <NavDropdown.Item href="/default-services">
                      Bookkeeping Services
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/bas-service">
                    <NavDropdown.Item href="/default-services">
                      Business Activity Statement (BAS)
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/business-structure">
                    <NavDropdown.Item href="/default-services">
                      Business Structures
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/ato-service">
                    <NavDropdown.Item href="/default-services">
                      Dealing with the ATO
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/financial-report-service">
                    <NavDropdown.Item href="/default-services">
                      Financial Reports
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/financial-statements">
                    <NavDropdown.Item href="/default-services">
                      Financial Statements
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/financial-strategies">
                    <NavDropdown.Item href="/default-services">
                      Financial Strategies
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/gst-payment">
                    <NavDropdown.Item href="/default-services">
                      GST Payment & Refund
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/ias-service">
                    <NavDropdown.Item href="/default-services">
                      Instalment Activity Statement (IAS)
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/payroll-service">
                    <NavDropdown.Item href="/default-services">
                      Payroll Services
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/preparation-schedule">
                    <NavDropdown.Item href="/default-services">
                      Preparation of Schedules
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/registration-service">
                    <NavDropdown.Item href="/default-services">
                      Registrations
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/tax-planning">
                    <NavDropdown.Item href="/default-services">
                      Tax Planning
                    </NavDropdown.Item>
                  </Link>
                  <Link to="/tax-returns">
                    <NavDropdown.Item href="/default-services">
                      Tax Returns
                    </NavDropdown.Item>
                  </Link>
                </NavDropdown>
              </NavLink>
              <Link className="smlscreen-nav" to="/industry">
                <NavLink href="/industry">Industry</NavLink>
              </Link>
              <Link className="smlscreen-nav" to="/contact-us">
                <NavLink href="/contact-us">Contact Us</NavLink>
              </Link>
              <Link className="smlscreen-nav" to="/inquiry">
                <NavLink href="/inquiry" className="btn-quote">
                  <PrimaryLink
                    css={tw`rounded-full text-white`}
                    href="/inquiry"
                  >
                    Make An Inquiry
                  </PrimaryLink>
                  {/* Make An Inquiry &nbsp;&nbsp; */}
                </NavLink>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header2;
