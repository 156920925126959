import { React, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { NavDropdown } from "react-bootstrap";

const Header = tw.header `
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div `inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-200
`;

export const PrimaryLink = tw(NavLink)
`
  lg:mx-0
  px-8 py-3 rounded bg-primary-400 text-white
  hocus:bg-primary-200 hocus:text-white focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)
`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex py-4 flex-1 items-center justify-around`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 border-none transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center h-4/6 border-none  rounded-lg text-primary-600 bg-white/85`}
  ${NavLinks} {
    ${tw`flex flex-col items-center mt-8 mb-4 text-primary-200`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const defaultLinks = [
    <NavLinks key={1}>
      <Link className="smlscreen-mt" to="/">
        <NavLink>Home</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/about-us">
        <NavLink>About Us</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/default-services">
        <NavLink>Services</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/industry">
        <NavLink>Industry</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/contact-us">
        <NavLink>Contact Us</NavLink>
      </Link>
      <Link className="smlscreen-mt" to="/inquiry">
        <PrimaryLink
          css={roundedHeaderButton && tw`rounded-full text-white`}
          href="/inquiry"
        >
          Make An Inquiry
        </PrimaryLink>
      </Link>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} style={{ width: "200px" }} alt="logo" />
      {/* Fortune */}
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <div className="pt-4 pb-4">
      <Header className={className || "header-light"}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}
          <MobileNavLinks
            initial={{ x: "150%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {links}
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
    </div>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};