import React from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { homepage } from "../MetaTags";
import MetaTags from "react-meta-tags";
import Hero from "components/HeroSection";
import ServicesCp from "components/ServicesCp";
import BenefitsCp from "components/BenefitsCp";
import IndustriesCp from "components/IndustriesCp";
import Values from "components/ThreeColSimple.js";

import ExecllenceIcon from "assets/vectors/excellence-01.svg";
import IntegrityIcon from "assets/vectors/integrity-01.svg";
import TrustIcon from "assets/vectors/trust-01.svg";
import Feature from "components/Feature";
import { Container } from "components/misc/Layouts";
import ValuesPrinciples from "components/ValuesPrinciples";
import ContactUsCp from "components/ContactUsCp";
import SoftwareSection from "components/SoftwareSection";
import CertificateBadges from "components/CertificateBadges";

const Homepage = () => {
  const ContainerFullWidth = tw.div`relative bg-primary-700  -mb-8 px-8`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const Heading = tw.h1`text-4xl sm:text-5xl text-primary-300 font-black tracking-wide text-center lg:text-center`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const HighlightedText = tw.span`text-primary-600`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw`w-full text-center`;
  const imageCss = tw`rounded-4xl`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{homepage.title}</title>
        <meta name="description" content={homepage.description} />
        <link rel="canonical" href={homepage.canonical} />
      </MetaTags>
      <Hero />
      <ContainerFullWidth>
        <section className="pt-4 pb-5 text-center">
          <Subheading>
            Fortune Accounting and Taxation Services in Perth, WA
          </Subheading>
          <Heading className="pt-4 pb-4 txt-cent">
            Accounting and Taxation Services
            <HighlightedText>
              <br /> from Reliable Tax Experts
            </HighlightedText>
          </Heading>
          <div tw="text-justify lg:text-justify mx-auto text-gray-600 max-w-5xl px-1 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Our owner, principal, and chief accountant, Mr. Lalji Patel, has
            over 12 years of experience in providing accounting services in
            Australia. He graduated with honors from Curtin University of
            Technology in Perth, Western Australia. Under his leadership, our
            company has been operating since 2022, and we have numerous
            satisfied clients who have benefitted from Mr. Lalji's extraordinary
            tax and accounting services.
            <br />
            <br />
            Our organization consists of specialists who have in-depth knowledge
            of tax laws and their applications. We assist our clients in
            reducing tax obligations or increasing tax refunds by making
            legitimate deductions. Our goal is to provide tailored solutions to
            our clients' unique tax and accounting needs, ensuring that they
            receive personalized attention and excellent service.
            <br />
            <br />
            With our expertise and experience, we are confident in our ability
            to provide top-notch tax and accounting services to businesses in
            Perth and beyond. We strive to stay up-to-date with the latest
            industry trends and regulations, enabling us to deliver accurate and
            timely services to our clients.
          </div>
        </section>
      </ContainerFullWidth>
      <CertificateBadges />
      <ServicesCp
        heading={
          <>
            Services We <HighlightedText> Offer</HighlightedText>
          </>
        }
      />
      <ContainerFullWidth>
        <BenefitsCp
          subheading={<Subheading>Accounting Is Forever</Subheading>}
          heading={
            <>
              Benefits of Accounting &
              <HighlightedText> Taxation Services</HighlightedText>
            </>
          }
        />
      </ContainerFullWidth>
      <IndustriesCp />
      <ContainerFullWidth>
        <ValuesPrinciples />
      </ContainerFullWidth>
      <Values
        heading={
          <>
            Our <HighlightedText>Values</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: IntegrityIcon,
            title: "Integrity",
            description:
              "We are Loyal, Honest, and Fair to all our clients. We follow principles and ethics to ensure quality of our services.",
          },
          {
            imageSrc: TrustIcon,
            title: "Trust",
            description:
              "Our clients’ trust emanates from a shared understanding of expectations founded on our professionalism, sincerity, and confidentiality.",
          },
          {
            imageSrc: ExecllenceIcon,
            title: "Execellence",
            description:
              "We make every effort to learn and excel in our services. We aspire to provide the highest level of service possible.",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-32! h-32!`}
      />
      <Feature />
      <SoftwareSection />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
      <div className="inquiry-side-bar">
        <Link to="/inquiry">
          <p className="text-mode">MAKE AN INQUIRY</p>
        </Link>
      </div>
    </div>
  );
};

export default Homepage;
