import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { financialReports } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import FinancialReportCp from "components/FinancialStategiesCp";
import SoftwareSection from "components/SoftwareSection";

const FinancialReportsService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{financialReports.title}</title>
        <meta name="description" content={financialReports.description} />
        <link rel="canonical" href={financialReports.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>FINANCIAL REPORTS</Heading>
          <h4>
            Financial reports are comprehensive records of a company's financial
            performance, <br /> used to monitor progress, make informed
            decisions and plan future activities
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              TAX <br /> SPECIALISTS
            </h2>
            <div className="vertical-line"></div>
            <p>
              Providing accurate and detailed financial reports to help you make
              informed decisions and achieve your financial goals
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          Financial Reporting for small businesses
        </Heading2>
        <div tw="text-justify mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Financial reports are an essential tool for business owners and
          investors to understand the financial health of a company. They
          provide a comprehensive view of a company's financial performance and
          help stakeholders make informed decisions.
          <br />
          Financial reports typically include balance sheets, income statements,
          cash flow statements, and notes to the financial statements. These
          reports provide information on a company's assets, liabilities,
          equity, revenues, expenses, and cash flows.
          <br />
          The balance sheet shows the company's assets, liabilities, and equity
          at a specific point in time, while the income statement shows the
          company's revenues and expenses over a given period. The cash flow
          statement provides information on the company's cash inflows and
          outflows during a particular period.
          <br />
          Notes to the financial statements provide additional information on
          the company's accounting policies, significant events, and
          contingencies that may impact its financial position. Understanding
          financial reports is crucial for making informed decisions about a
          company's future. They can help investors identify potential risks and
          opportunities and enable management to make strategic decisions based
          on accurate financial data.
        </div>
      </Container>
      <div className="bg-section pt-4 pb-4">
        <Container className="text-center mt-5 mb-5 diff-section">
          <Subheading className="text-center">Our Services</Subheading>
          <Heading2 className="text-center">
            Financial Reports at Fortune Perth, WA
          </Heading2>
          <p tw="text-left">
            As a leading accounting firm in Perth, we provide reliable and
            accurate financial report preparation services to businesses of all
            sizes. Our team of experienced accountants ensures that your
            financial reports are compliant with accounting standards and
            provide a comprehensive view of your company's financial position.
          </p>
          <p tw="text-left">
            Our financial reports cover various aspects of your business,
            including
          </p>
          <ul>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Profit and loss statements</h4>
            </li>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Balance Sheet</h4>
            </li>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Trial Balance</h4>
            </li>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Depreciation Schedule</h4>
            </li>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Beneficiary Distribution Accounts Statement</h4>
            </li>
            <li className="d-flex flex-row align-items-start">
              <img
                src={DimondIcon}
                style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
                alt=""
              />
              <h4>Cash Flow Statement</h4>
            </li>
          </ul>
          <p tw="text-left">
            These reports provide an overview of your company's performance and
            help you make informed decisions about future investments and
            business strategies. We also offer customized financial reports that
            can be tailored to your specific needs.
          </p>
          <p tw="text-left">
            At our firm, we prioritize transparency and communication, and we
            ensure that our clients are kept informed about their financial
            position. Our financial report services are available to businesses
            across Perth and Western Australia. Contact us today to learn more
            about how we can help your business with its financial reporting
            needs.
          </p>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default FinancialReportsService;
