import React from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { privacyPolicy } from "MetaTags";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import Header2 from "components/headers/Header2";

const HeadingRow = tw.div`flex`;
const Heading = tw.h1`text-4xl sm:text-5xl text-primary-300 font-black tracking-wide text-center text-primary-200 mb-10`;
const HighlightedText = tw.span`text-primary-600`;
const Link = tw.a`text-primary-400`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h2 {
    ${tw`text-3xl font-bold mt-10 text-primary-200`}
  }
  h2 {
    ${tw`text-2xl font-bold text-primary-200 mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold text-primary-200 mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ headingText = "Privacy Policy" }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="px-2">
      <MetaTags>
        <title>{privacyPolicy.title}</title>
        <meta name="description" content={privacyPolicy.description} />
        <link rel="canonical" href={privacyPolicy.canonical} />
      </MetaTags>
      <Header2 />
      <ContentWithPaddingXl>
        <HeadingRow>
          <Heading className="text-center">
            Privacy <HighlightedText> Policy</HighlightedText>
          </Heading>
        </HeadingRow>
        <Text>
          <h2>We collect information</h2>

          <p>
            We use technologies like cookies (small files stored by your
            browser), web beacons, or unique device identifiers to anonymously
            identify your computer or device so We can deliver a better
            experience. Our systems also log information like your browser,
            operating system and IP address.
          </p>

          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <p>
            We also knowingly contact or collect personal information from
            children under 13. If you believe we have inadvertently collected
            such information, please contact us so we can promptly obtain
            parental consent or remove the information.
          </p>
          <p>
            Our service does not currently recognize the “Do Not Track” signal
            that may be available in some web browsers.
          </p>

          <h2>Interpretation and Definitions</h2>
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </p>
          <p>
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or in plural.
          </p>

          <h2>How we collect Personally identifiable information</h2>
          <p>
            When you register we may collect a variety of information, including
            your name, mailing address, email address.
          </p>
          <p>
            We may use cookies to identify that you’ve logged in to the
            Services. Although most browsers automatically accept cookies, you
            can change your browser options to stop automatically accepting
            cookies or to prompt you before accepting cookies. Please note,
            however, that if you don’t accept cookies, you will not be able to
            stay automatically logged in to the Services.
          </p>
          <h2>How we use your Personally identifiable information</h2>
          <p>
            The personal information we collect allows us to keep you posted on
            Duqm Shipping & Marine Services LLC latest Services and updates.
            Duqm Shipping & Marine Services LLC and its affiliates may share
            personal information with each other and use it consistent with this
            Privacy Policy. They may also combine it with other information to
            provide and improve our Services.
          </p>
          <p>
            We also use personal information to help us develop, deliver, and
            improve our Services. From time to time, we may use your Personally
            identifiable information to send important notices, such as
            communications about changes to our terms, conditions, and policies.
            If you don’t want to be on our mailing list, you can opt out anytime
            by unregistering at info@fortuneaccounts.com.au We may also use Personally
            identifiable information for internal purposes such as auditing,
            data analysis, and research to improve Fortune Accounting & Taxation services and customer communications.
          </p>

          <h2>We may collect and share anonymous location data.</h2>
          <p>
            To customize our service for you, we and our partners may collect,
            use, and share precise location data, including the real-time
            geographic location of your computer or device. This location data
            is collected anonymously in a form that does not personally identify
            you and is used only to provide and improve our service. We may
            obtain your consent on your first use of the service.
          </p>
          <h2>You can request to see your personal data.</h2>
          <p>
            We do not permit direct association with any specific individual.
          </p>
          <h2>We keep personal data for a limited period.</h2>
          <p>
            We do store any of your personal information on our server also But
            Google Game service can collect your personal in for that please
            read google play game service privacy policy.
          </p>
          <h2>We don’t share your personal data with other companies.</h2>
          <p>We do not store any of your personal information</p>
          <h2>Ad companies collect anonymous data. You can opt out.</h2>
          <p>
            Ad companies may use and collect anonymous data about your interests
            to customize content and advertising here and in other sites and
            applications. Interest and location data may be linked to your
            device, but is not linked to your identity. Click to see company
            privacy policies and opt-out choices:
          </p>
          <p>
            Google Ads (Admeld, Admob, Channel Intelligence, DoubleClick,
            Display Network, Teracent)
          </p>
          <p>
            Admeld Admeld pioneered the private ad exchange and built technology
            that made it easy for publishers to identify new opportunities and
            control how every impression is sold. Admob AdMob has been rebuilt,
            leveraging the best of Google’s ad platforms. Channel Intelligence
            Channel intelligence optimizes product visibility on shopping
            engines. With our bidding expertise, data optimization technology,
            and data quality monitoring, we elevate performance on shopping
            engines. DoubleClick DoubleClick for Publishers (DFP) is a
            comprehensive hosted ad serving platform that streamlines ad
            management functions and allows generating even-greater advertising
            margins. Display Network The Google Display Network helps generate
            awareness by getting ads in front of consumers, so they can learn
            about the business as they consider their options. Teracent
            Teracent’s Intelligent Display Advertising creates display ads
            entirely customized to the specific consumer and site proposition
            for marketers that was, until now, cost and time prohibitive.Privacy
            Policy and Choices
          </p>
          <h2>You can ask privacy questions.</h2>
          <p>
            If you have any questions or concerns about our privacy policies,
            please contact
            <a href="">
              <Link> info@fortuneaccounts.com.au </Link>
            </a>
          </p>
          <h2>Service providers access data on our behalf.</h2>
          <p>
            In order to serve you, we may share your personal and anonymous
            information with other companies, including vendors and contractors.
            Their use of information is limited to these purposes, and subject
            to agreements that require them to keep the information
            confidential. Our vendors provide assurance that they take
            reasonable steps to safeguard the data they hold on our behalf,
            although data security cannot be guaranteed.
          </p>
          <p>
            Analytics companies may access anonymous data (such as your IP
            address or device ID) to help us understand how our services are
            used. They use this data solely on our behalf. They do not share it
            except in aggregate form; no data is shared as to any individual
            user. Click to see company privacy policies that govern their use of
            data.
          </p>
          <p>
            Google Ads (Admeld, Admob, Channel Intelligence, DoubleClick,
            Display Network, Teracent)
          </p>
          <p>
            Admeld Admeld pioneered the private ad exchange and built technology
            that made it easy for publishers to identify new opportunities and
            control how every impression is sold. Admob AdMob has been rebuilt,
            leveraging the best of Google’s ad platforms. Channel Intelligence
            Channel intelligence optimizes product visibility on shopping
            engines. With our bidding expertise, data optimization technology,
            and data quality monitoring, we elevate performance on shopping
            engines. DoubleClick DoubleClick for Publishers (DFP) is a
            comprehensive hosted ad serving platform that streamlines ad
            management functions and allows generating even-greater advertising
            margins. Display Network The Google Display Network helps generate
            awareness by getting ads in front of consumers, so they can learn
            about the business as they consider their options. Teracent
            Teracent’s Intelligent Display Advertising creates display ads
            entirely customized to the specific consumer and site proposition
            for marketers that was, until now, cost and time prohibitive.Privacy
            Policy and Choices
          </p>
          <h2>Google Analytics</h2>
          <p>
            Google Analytics is a web analytics tool that helps website owners
            understand how visitors engage with their website. Google Analytics
            customers can view a variety of reports about how visitors interact
            with their website so they can improve it. Google Analytics collects
            information anonymously. It reports website trends without
            identifying individual visitors.Privacy Policy and Choices
          </p>
          <h2>We take steps to protect personal information</h2>
          <p>
            We store any of your personal information Information we collect may
            be stored or processed on computers located in any country where we
            do business. We cannot guarantee to protect your information from
            hackers or human error.
          </p>
          <h2>Special situations may require disclosure of your data.</h2>
          <p>
            To operate the service, we also may make identifiable and anonymous
            information available to third parties in these limited
            circumstances: (1) with your express consent, (2) when we have a
            good faith belief it is required by law, (3) when we have a good
            faith belief it is necessary to protect our rights or property, or
            (4) to any successor or purchaser in a merger, acquisition,
            liquidation, dissolution or sale of assets. Your consent will not be
            required for disclosure in these cases, but we will attempt to
            notify you, to the extent permitted by law to do so.
          </p>
          <h2>You can review more privacy-related information.</h2>
          <p>
            This privacy policy was last updated on : May 23, 2023. Our privacy
            policy may change from time to time. If we make any material changes
            to our policies, we will place a prominent notice on our website or
            application. If the change materially affects registered users, we
            will send a notice to you by email, push notification or text
          </p>
          <p>
            ​Contact Us or email Us at
            <a href="">
              <Link> info@fortuneaccounts.com.au </Link>
            </a>
            any query regarding privacy policy.
          </p>
          <p>© 2023 – Fortune Accounting & Taxation Service</p>
        </Text>
      </ContentWithPaddingXl>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
    </Container>
  );
};
