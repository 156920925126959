import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { taxplanning } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const TaxPlanning = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{taxplanning.title}</title>
        <meta name="description" content={taxplanning.description} />
        <link rel="canonical" href={taxplanning.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>TAX PLANNING</Heading>
          <h4>
            Tax planning is the process of analyzing a financial situation or{" "}
            <br /> plan from a tax perspective to ensure tax efficiency.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              TAX PLANNING <br /> ADVISOR
            </h2>
            <div className="vertical-line"></div>
            <p>
              We offer tax planning to minimize your tax liability and optimize{" "}
              <br /> your financial position in compliance with tax laws.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Tax Planning Services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Tax planning involves a proactive approach to managing an individual's
          or business's finances to minimize the amount of taxes owed to the
          government. It focuses on maximizing deductions, credits, and other
          tax-saving opportunities while staying in compliance with tax laws and
          regulations. This type of planning takes into account the individual's
          or business's income, expenses, investments, and other financial
          activities to help reduce the tax burden. The main goal is to legally
          reduce the tax bill and increase profits.
          <br />
          <br />
          There are different strategies used in tax planning, such as deferring
          income, accelerating deductions, taking advantage of tax credits, and
          investing in tax-advantaged accounts. The key is to have a
          comprehensive plan that is flexible enough to adjust to changing
          circumstances while remaining tax-efficient.By working with a tax
          professional, individuals and businesses can create a tax plan that
          helps them save money and stay compliant with tax laws. It is
          important to plan ahead and review the plan regularly to ensure that
          it is still effective and aligned with current tax laws and
          regulations
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            We assist you in planning your tax
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Tax planning is a critical aspect of managing personal and business
            finances. It involves structuring financial affairs in a manner that
            minimizes tax liabilities while maximizing benefits and returns. At
            our firm, we provide comprehensive tax planning services to
            individuals and businesses to help them achieve their accounting
            goals.
            <br />
            <br />
            Our tax planning services begin with a detailed analysis of our
            client's accounting situation, taking into account their income,
            expenses, assets, and liabilities. We work closely with our clients
            to understand their accounting goals, future plans, and any potential
            tax issues they may face.Based on this analysis, we develop a
            customized tax plan that takes advantage of all available tax
            deductions, credits, and exemptions. We also provide advice on the
            best tax strategies for investments, business operations, retirement
            planning, and estate planning.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default TaxPlanning;
