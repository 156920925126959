import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "./misc/Headings.js";
import { PrimaryButton } from "./misc/Buttons.js";

//
import SoftwareBg1 from "assets/pcxero.svg";
import SoftwareBg2 from "assets/pcbgsage.svg";
import SoftwareBg3 from "assets/pcbgmyob.svg";
import SoftwareBg4 from "assets/pcbgquickbooks.svg";
//
import Xero from "assets/xero.svg";
import Quickbooks from "assets/quickbooks.svg";
import SageHandisoft from "assets/sage-handisoft.svg";
import MyOb from "assets/myob.svg";

import { ReactComponent as QuotesLeftIcon } from "images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "images/quotes-r.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
// const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;

// const ImageAndControlContainer = tw.div`relative outline-none`;
// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
// ]);

// const ControlContainer = tw.div`absolute bottom-0 right-0 px-6 py-4 `;
// const ControlButton = styled(PrimaryButton)`
//   ${tw`mx-3 rounded-full text-white p-2`}
//   svg {
//     ${tw`w-5 h-5`}
//   }
// `;

// const TextContainer = styled.div((props) => [
//   tw`flex flex-col w-full lg:w-7/12`,
//   props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
// ]);

const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row lg:flex-row-reverse`;

const ImageAndControlContainer = tw.div`relative outline-none lg:w-5/12`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
]);

const ControlContainer = tw.div`absolute bottom-0 lg:right-0 px-6 py-2 flex justify-center items-center `;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full border-none text-white p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const QuoteContainer = tw.div`relative mt-10 lg:mt-2`;
const Quote = tw.blockquote`max-w-md px-4 text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h4`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-6 h-6 opacity-75 text-primary-500 inline-block mr-1 -mt-3`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  subheading = "",
  heading = "Accounting Softwares We Use",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing elit, sed do eius tempor incididunt ut labore et dolore magna magna adipiscing tempor incididunt ut labore et dolore magna magna adipiscing Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores",
  testimonials = null,
  textOnLeft = false,
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc: SoftwareBg1,
      profileImageSrc: Xero,
      quote:
        "At our accounting firm, we understand the importance of utilizing the latest technology to ensure our clients' accounting success. That's why we use Xero accounting software to provide efficient and accurate accounting solutions. Xero allows for real-time tracking of financial data, easy access to financial reports, and secure cloud-based storage of financial information. Our team of Xero-certified professionals will work closely with you to ensure that all of your accounting needs are met with the highest level of accuracy and timeliness. With Xero, you can have peace of mind knowing that your financial data is in safe hands.",
      customerName: "Xero",
      customerTitle: "CEO, Delos Inc.",
    },
    {
      imageSrc: SoftwareBg2,
      profileImageSrc: SageHandisoft,
      quote:
        "Sage HandiSoft is a leading software in the accounting industry, providing secure data storage and peace of mind to clients. Its advanced features streamline financial processes, ensuring accurate and efficient accounting. With our expertise in using Sage HandiSoft, we can provide timely and comprehensive financial reporting, while minimizing the risk of errors. Our clients can trust that their sensitive financial information is safe and confidential. By using Sage HandiSoft, we can offer superior services to our clients, ensuring they receive the highest level of accuracy, efficiency, and security for their financial data.",
      customerName: "Sage Handisoft",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: SoftwareBg3,
      profileImageSrc: MyOb,
      quote:
        "Using MYOB software for accounting and bookkeeping services can bring many benefits to clients, including streamlined processes, accurate financial data, easy access to reports, and simplified tax compliance. The software's user-friendly interface makes it easy for clients to input their financial information, while the cloud-based system allows for secure and remote access to financial data anytime, anywhere. With MYOB's automated features, clients can save time on manual data entry, and the real-time data updates provide better visibility into their financial performance. Additionally, MYOB offers integrations with other business software, providing clients with a comprehensive business management solution. Overall, using MYOB software can enhance the efficiency, accuracy, and transparency of clients' financial management processes.",
      customerName: "My Ob",
      customerTitle: "Founder, EventsNYC",
    },
    {
      imageSrc: SoftwareBg4,
      profileImageSrc: Quickbooks,
      quote:
        "At our accounting firm, we use QuickBooks software for client accounting needs. By using QuickBooks, clients can enjoy streamlined financial management, easy tracking of expenses, and efficient invoicing and payment processing. QuickBooks also provides real-time reporting and insights, allowing clients to make informed decisions for their business. Additionally, QuickBooks offers cloud-based access, so clients can access their financial information anytime, anywhere, and on any device. With our expertise and the use of QuickBooks, clients can rest assured that their accounting needs are in good hands and their financial data is organized and secure.",
      customerName: "Quick Books",
      customerTitle: "Founder, EventsNYC",
    },
  ];

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials;

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo
          tw="text-center lg:hidden"
          subheading={subheading}
          heading={heading}
        />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider
                arrows={false}
                ref={setImageSliderRef}
                asNavFor={textSliderRef}
                fade={true}
              >
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer className="ctrl-container">
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo
                  tw="hidden lg:block"
                  subheading={subheading}
                  heading={heading}
                />
                <TestimonialTextSlider
                  arrows={false}
                  ref={setTextSliderRef}
                  asNavFor={imageSliderRef}
                  fade={true}
                >
                  {testimonials.map((testimonial, index) => (
                    <TestimonialText key={index}>
                      <CustomerInfo>
                        <CustomerProfilePicture
                          src={testimonial.profileImageSrc}
                          alt={testimonial.customerName}
                        />
                        <CustomerTextInfo>
                          <CustomerName>
                            {testimonial.customerName}
                          </CustomerName>
                        </CustomerTextInfo>
                      </CustomerInfo>
                      <QuoteContainer>
                        <Quote>
                          <QuotesLeft />
                          {testimonial.quote}
                          <QuotesRight />
                        </Quote>
                      </QuoteContainer>
                    </TestimonialText>
                  ))}
                </TestimonialTextSlider>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      <DecoratorBlob1 />
      {/* <DecoratorBlob2 /> */}
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
