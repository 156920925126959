import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { gstpayment } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const GSTPayement = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{gstpayment.title}</title>
        <meta name="description" content={gstpayment.description} />
        <link rel="canonical" href={gstpayment.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>GST PAYMENT & REFUND</Heading>
          <h4>
            GST payment is tax owed, while refund is excess tax paid. <br />
            Both can be filed electronically through the ATO portal.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              ACCOUNTING <br /> SPECIALISTS
            </h2>
            <div className="vertical-line"></div>
            <p>
              We can assist you with preparing and filing GST returns, <br />
              managing GST payments, and navigating the refund process.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Goods and Services Tax (GST) Payment & Refund services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          At our company in Perth, we offer exceptional GST payment and refund
          services for our clients. Our team of experienced professionals
          ensures timely and accurate submission of your GST returns, allowing
          you to focus on your business operations without worrying about tax
          compliance. We also offer expert advice on GST-related matters and
          provide you with a clear understanding of your GST obligations.
          <br />
          <br />
          Our GST payment and refund services include reviewing your business
          transactions, calculating the GST payable or refundable, preparing and
          submitting your BAS on your behalf, and dealing with any ATO enquiries
          or audits. With our commitment to quality and customer satisfaction,
          you can trust us to handle all your GST payment and refund needs
          efficiently and transparently.
          <br />
          <br />
          Partnering with us means you can have peace of mind knowing that you
          are receiving the best possible service for your GST payment and
          refund requirements. Contact us today to learn more about how we can
          help you.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            How Fortune calculates your GST Payments and Refunds
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            GST Payment and Refunds Services are a critical aspect of the
            Australian taxation system, and businesses that are registered for
            GST are required to collect and remit GST on behalf of the
            government. GST is a broad-based tax that applies to most goods,
            services and other items sold or consumed in Australia, and
            currently stands at a rate of 10%.
            <br />
            <br />
            To comply with GST requirements, businesses must lodge a Business
            Activity Statement (BAS) outlining the GST collected and paid during
            a particular reporting period. This statement is lodged either
            monthly, quarterly or annually depending on the business' turnover.
            Proper management of GST payment and refunds is crucial for
            businesses to ensure they comply with taxation laws and avoid any
            penalties. Furthermore, efficient management of GST refunds can help
            businesses improve their cash flow. GST refunds can be claimed when
            the GST paid is more than the GST collected, and can be used to
            offset the GST payable in future periods or refunded as cash.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default GSTPayement;
