import { React, useState, useEffect } from "react";
import tw from "twin.macro";
import { useLocation } from "react-router-dom";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import { masterData } from "serviceData";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";

const ServicePage = () => {
  const location = useLocation();
  const xyz = location.state.service;
  console.log(xyz);
  const [service, setService] = useState("default");
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.span`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-300 text-center text-2xl lg:text-4xl font-semibold mt-3 mb-3`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    masterData.map((service) => {
      const found = service.id === xyz;
      if (found) return setService(service);
      else return "default";
    });
  }, []);
  console.log(service);
  return (
    <div>
      {/* {masterData.map((service) => (service.id === xyz ? setService(xyz) : ""))} */}

      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>{service.heading ? service.heading : "Services"}</Heading>
          {/* <Heading>Services</Heading> */}
          <h4>
            {service.brd_description
              ? service.brd_description
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod <br /> tempor incididunt ut labore et dolore magna adipiscing elit, sed do eius tempor <br /> incididunt ut labore et dolore magna magna adipiscing tempor incididunt ut labore"}
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              {service.brd_btm_heading
                ? service.brd_btm_heading.split(" ").reduce((acc, curr) => {
                    return acc.length === 0 ? [curr] : [...acc, <br />, curr];
                  }, [])
                : "PROFESSIONAL SERVICES"}
            </h2>
            <div className="vertical-line"></div>
            <p>
              {/* {service.brd_btm_description} */}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore <br /> et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <HighlightedText className="pt-3 pb-4 px-4">
          {service.section1_heading
            ? service.section1_heading
            : "Professional Services for Enhance Control of Your Business Finance"}
        </HighlightedText>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          {service.section1_para
            ? service.section
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"}
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-center">
          <Subheading className="px-4">Our Services</Subheading>
          <Heading className="pt-3 pb-4 text-center">
            {service.section2_heading
              ? service.section2_heading
              : "List of Bookkeeping Services We Offer"}
          </Heading>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            {service.section2_para
              ? service.section2_para
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"}
          </div>
        </Container>
      </div>
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default ServicePage;
