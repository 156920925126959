import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { bas } from "MetaTags";
import { useEffect } from "react";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import AllIndustriesCp from "components/AllIndustriesCp";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import SoftwareSection from "components/SoftwareSection";

const BasService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{bas.title}</title>
        <meta name="description" content={bas.description} />
        <link rel="canonical" href={bas.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>Business Activity Statement (BAS)</Heading>
          <h4>
            A tax reporting requirement for Australian businesses, <br />{" "}
            summarizing their tax obligations, including GST, PAYG, and other
            taxes.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              BAS <br /> Agent
            </h2>
            <div className="vertical-line"></div>
            <p>
              We offer top-notch BAS services to help businesses meet their{" "}
              <br />
              compliance obligations and streamline their accounting processes.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-left">
          Business Activity Statement (BAS) services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Our firm in Perth, Australia provides top-notch Business Activity
          Statement (BAS) services to ensure your business stays compliant with
          ATO regulations. Our team of experienced BAS agents delivers timely
          and accurate BAS lodgment services, along with assistance in managing
          your GST payments and refunds. We understand the importance of
          financial reports preparation and provide comprehensive schedules and
          ATO dealings for your peace of mind.
          <br />
          <br />
          Our expertise extends to business structure setup and tax planning,
          where we create strategies tailored to your business objectives. Our
          financial strategies can also help maximize your profits and minimize
          tax liabilities. In addition, we offer registration and payroll
          services to streamline your business operations. We are committed to
          transparency, and we provide regular updates and detailed reports to
          our clients.
        </div>
      </Container>
      <div className="bg-section">
        <Container className="pt-4 pb-5 text-left">
          <Subheading>Our Services</Subheading>
          <Heading2 className="pt-3 pb-4 text-left">
            Understanding Business Activity Statement (BAS):
          </Heading2>
          <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
            Business Activity Statement (BAS) is a tax reporting requirement by
            the Australian Taxation Office (ATO) for all businesses registered
            for Goods and Services Tax (GST), Pay As You Go (PAYG) withholding,
            and other tax obligations. It is a way for businesses to report and
            pay their tax liabilities to the ATO.
            <br />
            <br />
            BAS includes details such as total sales, GST collected, GST paid,
            and other relevant information. It is usually lodged monthly or
            quarterly, depending on the business's turnover. Failure to lodge a
            BAS can result in penalties and interest charges. To avoid any
            complications, it is recommended to seek assistance from a
            registered BAS agent. A BAS agent can help with BAS preparation,
            lodgment, and dealing with the ATO on behalf of the business. This
            ensures that businesses remain compliant and avoids any unwanted
            fines or penalties.
          </div>
        </Container>
      </div>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default BasService;
