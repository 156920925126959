import React from "react";
import { useEffect } from "react";
import tw from "twin.macro";
import { ReactComponent as MySVG } from "assets/icons/up-arrow.svg";
import ScrollToTop from "react-scroll-to-top";
import { css } from "styled-components/macro";
import breadImg from "assets/about-us-brdcrms.jpg";
import SupportBusiness from "components/SuppurtBusiness";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import { aboutUs } from "../MetaTags";
import MetaTags from "react-meta-tags";
import Header2 from "components/headers/Header2";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const TopHeading = tw.h1`mt-4 font-black text-4xl sm:text-5xl text-primary-300 tracking-wide text-center text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Heading = tw.h2`mt-4 font-black text-4xl sm:text-5xl text-primary-300 tracking-wide text-center text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 md:text-left text-center text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200  -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
    alt: "Professional Tax Advisors in Perth, WA | Accounting Agency | Fortune Accounts",
  };
  return (
    <div>
      <MetaTags>
        <title>{aboutUs.title}</title>
        <meta name="description" content={aboutUs.description} />
        <link rel="canonical" href={aboutUs.canonical} />
      </MetaTags>
      {/* <Header /> */}
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <TopHeading>
            ABOUT
            <HighlightedText> FORTUNE</HighlightedText>
          </TopHeading>
          <h4>
            With over a decade of experience, we are a trusted accounting and
            taxation <br /> service provider in Perth. Our team is dedicated to
            providing personalized <br /> and reliable solutions to meet our
            clients' needs.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              PROFESSIONAL <br /> PEOPLE
            </h2>
            <div className="vertical-line"></div>
            <p>
              Our firm follows a personalized approach to provide tailored
              solutions for each client. <br /> We prioritize clear
              communication and ensure compliance with all relevant regulations.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Subheading className="text-center">Our Story</Subheading>
        <Heading className="pt-3 pb-4 text-center">
          Who
          <HighlightedText> We Are</HighlightedText>
        </Heading>
        <div tw="text-justify lg:text-center mx-auto text-gray-600 max-w-full px-2 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Our company has been operating since 2022 under the direction of Mr.
          Lalji Patel, our owner, principal, and chief accountant. He has over
          12 years of expertise in providing accounting services in Australia.
          He graduated with honors from Curtin University of Technology in
          Perth, Western Australia. Several satisfied clients benefit from Mr.
          Lalji’s extraordinary tax and accounting services. Specialists in our
          organization have in-depth knowledge of tax laws and their
          applications. We assist our clients in reducing tax obligations or
          increasing tax refunds by making legitimate deductions.
        </div>
      </Container>
      <Container>
        <div className="mission-vision">
          <div className="mission-vision-box">
            <div className="vertical-line-m "></div>
            <h2>OUR VISION</h2>
            <p>
              Fortune Accounting & Taxation Services’s primary objective is to
              guarantee that our clients feel special and valued.We want our
              clients to feel confident that their tax issues are being
              carefully analyzed. The tax return procedure can be challenging,
              time- consuming, and frustrating for an individual. We understand
              that the laws and frequent changes can be complicated, which is
              why we are committed to identifying and informing clients about
              all of these changes so that your tax affairs can be handled
              effectively and with as little stress and inconvenience as
              possible.
            </p>
          </div>
          <div className="mission-vision-box">
            <div className="vertical-line-m "></div>
            <h2>OUR MISSION</h2>
            <p>
              The mission of Fortune Accounting and Taxation Service is to
              satisfy the requirements of the clients we serve. At Fortune
              Accounting and Taxation Service, we are zealous to provide the
              best solutions for any taxation problems clients are experiencing.
              Our business has accomplished several difficult jobs and aspires
              to provide the best services in the future. We strive to learn and
              master the expertise necessary for the services. Our goal is to
              make our services available to as many people as possible. We can
              assist many individuals or businesses with Accounting and Taxation
              services.
            </p>
          </div>
        </div>
      </Container>
      <SupportBusiness />
      <div className="section-blue">
        <Container>
          <Subheading>Think accounting, Think us</Subheading>
          <Heading>Our Approach</Heading>
          <p>
            Our approach at our accounting and taxation service is
            client-centric, focusing on building long-term relationships with
            our clients. We believe in taking a proactive approach and providing
            forward-thinking solutions to help our clients achieve their
            accounting goals. Our team of qualified and experienced accountants
            work collaboratively with our clients to ensure that we understand
            their unique needs and tailor our services accordingly.
          </p>
          <p>
            We are committed to providing transparent and reliable services, and
            we use the latest technology and tools to ensure accuracy and
            efficiency. At our firm, we strive to deliver exceptional service
            that exceeds our clients expectations and helps them succeed in
            their business endeavors.
          </p>
        </Container>
      </div>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">
          Quality Service, Quality Accounting
        </Subheading>
        <Heading className="text-center">What Makes Fortune Different</Heading>
        <p tw="text-left">
          At our accounting and taxation service, we pride ourselves on being
          different. We believe our personalized approach and attention to
          detail set us apart. Here are some of the key factors that make us
          different:
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>
              We provide customized solutions that fit the unique needs of our
              clients and their businesses.
            </h4>
          </li>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>
              We maintain open lines of communication with our clients and make
              sure they understand every aspect of their accounting situation.
            </h4>
          </li>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>
              Our team of experienced accountants and tax professionals have the
              knowledge and expertise to handle all of your accounting needs.
            </h4>
          </li>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>
              We take a proactive approach to managing our clients' finances,
              constantly looking for ways to optimize their accounting situation
              and minimize their tax burden.
            </h4>
          </li>
        </ul>
      </Container>
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
      <ScrollToTop
        smooth
        component={<MySVG style={{ padding: "5px" }} />}
        top={800}
      />
    </div>
  );
};

export default AboutUs;
