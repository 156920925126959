import { useState } from "react";
import { URL } from "Config";
import logo from "assets/logo-footer_Fortune.svg";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { Alert, Col, Row, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Phone } from "react-bootstrap-icons";

function InquiryForm() {
  const to_email = "info@fortuneaccounts.com.au";
  const cc = 'arkayappsseo@gmail.com';

  const title = "Website Inquiry";
  const subject = "Inquiry Form - Fortune";
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [fname, setFirstName] = useState(null);
  const [lname, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [company, setCompany] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Sundrop Solar</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }

        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            margin: 10px 0;
        }

        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:150px;
            height:auto;
        }

        .text-center {
            text-align: center !important;
        }

        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }

        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }

        .card-header {
            background: #136BCE;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }

        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }

        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #136BCE;
        }

        .card-body p strong {
            color: #333;
        }

        .card-body p strong.heading {
            color: #136BCE;
        }

        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>

<body>
    <div class="content">
        <div class="text-center">
            <img src="https://fortuneaccounts.com.au/logo.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received an Inquiry !</p>
        </div>
        <div class="card-body">
            <p><strong>First Name : </strong>${fname}</p>
            <p><strong>Last Name : </strong>${lname}</p>
            <p><strong>Company  : </strong>${company}</p>
            <p><strong>Service Type : </strong>${service}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Mobile No: </strong>${mobile}</p>
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 Fortune Accounting & Taxation Services</p>
        </div>
    </div>
</body>

</html>`;

  const mailError = <p>{error}</p>;

  const handleFirstName = (e) => {
    const value = e.target.value;
    setFirstName(value);
    setError(null);
  };

  const handleLastName = (e) => {
    const value = e.target.value;
    setLastName(value);
    setError(null);
  };

  const handleCompany = (e) => {
    const value = e.target.value;
    setCompany(value);
    setError(null);
  };

  const handleService = (e) => {
    const value = e.target.value;
    setService(value);
    setError(null);
  };

  const handleEmail = (e) => {
    var value = e.target.value;
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMobile = (e) => {
    const value = e.target.value;
    setMobile(value);
    setError(null);
  };
  const handleMessage = (e) => {
    const value = e.target.value;
    setMessage(value);
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
    if (fname === null) {
      setError(<Alert variant={"danger"}>First Name Required</Alert>);
    } else if (email === null) {
      setError(<Alert variant={"danger"}>Email Required</Alert>);
    } else if (mobile === null) {
      setError(<Alert variant={"danger"}>Phone Required</Alert>);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
    } else {
      setDisabled(true);
      var formData = new FormData();
      formData.append("title", title);
      formData.append("to_email", to_email);
      formData.append("cc", cc);
      formData.append("template", template);
      formData.append("subject", subject);
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const url = URL + "/send-mail";
      axios
        .post(url, formData, headerConfig)
        .then((response) => {
          console.log(response);
          document.getElementById("contact-us").reset();
          setDisabled(false);
          navigate("/thank-you");
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response) {
            console.log(error.response);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{error.message}</Alert>);
          setDisabled(false);
        });
    }
  };

  return (
    <Form id="contact-us" onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            name="firstName"
            label="First Name"
            value={fname}
            onChange={handleFirstName}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="lastName"
            label="Last Name"
            value={lname}
            onChange={handleLastName}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="companyName"
            label="Company Name"
            value={company}
            onChange={handleCompany}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmail}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="phone"
            label="Phone Number"
            type="tel"
            value={mobile}
            onChange={handleMobile}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="service-label">Service</InputLabel>
            <Select
              labelId="service-label"
              name="service"
              value={service}
              onChange={handleService}
            >
              <MenuItem value="Advisory Services">Advisory Services</MenuItem>
              <MenuItem value="Bookkeeping Services">
                Bookkeeping Services
              </MenuItem>
              <MenuItem value="Business Structures">
                Business Structures
              </MenuItem>
              <MenuItem value="Dealing with the ATO">
                Dealing with the ATO
              </MenuItem>
              <MenuItem value="Financial Reports">Financial Reports</MenuItem>
              <MenuItem value="Financial Statements">
                Financial Statements
              </MenuItem>
              <MenuItem value="Financial Strategies">
                Financial Strategies
              </MenuItem>
              <MenuItem value="GST Payment & Refund">
                GST Payment & Refund
              </MenuItem>
              <MenuItem value="Instalment Activity Statements (IAS)">
                Instalment Activity Statements (IAS)
              </MenuItem>
              <MenuItem value="Payroll Services">Payroll Services</MenuItem>
              <MenuItem value="Preparation of Schedules">
                Preparation of Schedules
              </MenuItem>
              <MenuItem value="Registrations">Registrations</MenuItem>
              <MenuItem value="Tax Planning">Tax Planning</MenuItem>
              <MenuItem value="Tax Returns">Tax Returns</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            label="Message"
            value={message}
            onChange={handleMessage}
            fullWidth
            multiline
            rows={4}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          {mailError}
        </Grid>
      </Grid>
    </Form>
  );
}

export default InquiryForm;
