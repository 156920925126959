import React from "react";
import tw from "twin.macro";
import { MetaTags } from "react-meta-tags";
import { prepationSchedules } from "MetaTags";
import { css } from "styled-components/macro";
import breadImg from "assets/services-brdcrms.jpeg";
import { useEffect } from "react";
import ContactUsCp from "components/ContactUsCp";
import DimondIcon from "assets/icons/diamond-01.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { Container } from "react-bootstrap";
import IndustriesCp from "components/IndustriesCp";
import Header2 from "components/headers/Header2";
import TaxAdvisoryServicesCp from "components/TaxAdvisoryServicesCp";
import SoftwareSection from "components/SoftwareSection";

const PreparationSchedulesService = () => {
  const Heading = tw.h1`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl md:text-left leading-tight text-4xl sm:text-5xl text-primary-300`;
  const Heading2 = tw.h2`mt-4 font-black text-left text-3xl text-primary-300 sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
  const Subheading = tw.h3`tracking-wider text-primary-200 text-base font-medium`;
  const ContainerFullWidth2 = tw.div`relative bg-primary-200 -mb-8 px-8`;
  const HighlightedText = tw.span`text-primary-600`;
  const myStyle = {
    background: `url(${breadImg})`,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{prepationSchedules.title}</title>
        <meta name="description" content={prepationSchedules.description} />
        <link rel="canonical" href={prepationSchedules.canonical} />
      </MetaTags>
      <Header2 />
      <section className="breadcrumbs" style={myStyle}>
        <div className="container">
          <Heading>Preparation of Schedules</Heading>
          <h4>
            Creating schedules involves organizing and <br /> categorizing
            financial data for reporting and analysis purposes.
          </h4>
        </div>
      </section>

      <div className="breadbox">
        <Container>
          <div className="breadbox-content">
            <h2>
              BEST <br /> ACCOUNTING FIRM
            </h2>
            <div className="vertical-line"></div>
            <p>
              Providing top-notch schedule preparation service to ensure
              accurate and <br /> timely reporting for your business needs.
            </p>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-5 text-center">
        <Heading2 className="pt-3 pb-4 text-center">
          Complementary Accounting Services
        </Heading2>
        <div tw="text-left mx-auto text-gray-600 max-w-full px-5 mt-4 mb-4 text-sm md:text-base lg:text-lg font-medium leading-loose text-secondary-100">
          Preparation of schedules refers to the process of creating supporting
          documentation and supplementary reports that provide more detailed
          information on specific aspects of a company's financial statements.
          These schedules are usually prepared to give a more in-depth
          understanding of various financial transactions and events. Some
          common schedules include fixed assets schedules, inventory schedules,
          and accounts receivable schedules.
          <br />
          Preparing these schedules helps to ensure that the financial
          statements are accurate and complete. It can also provide a detailed
          analysis of a company's financial performance and position. This
          information can be useful for decision-making purposes, such as
          determining whether to invest in a company or whether to extend credit
          to a customer.
          <br />
          Overall, preparation of schedules is an important component of
          financial reporting and analysis, and it can provide valuable insights
          into a company's financial health. By engaging a professional service
          provider in Perth WA, businesses can ensure that their schedules are
          accurate and in compliance with accounting standards.
        </div>
      </Container>
      <Container className="text-center mt-5 mb-5 diff-section">
        <Subheading className="text-center">Our Services</Subheading>
        <Heading2 className="text-center">
          Main Types of Financial Strategies
        </Heading2>
        <p tw="text-left">
          At our firm in Perth, Western Australia, we specialize in providing
          preparation of schedules service to businesses of all sizes. We have
          extensive experience in preparing schedules for various purposes, such
          as financial reporting, tax compliance, and internal management. We
          understand that schedules play a vital role in presenting the accurate
          financial picture of any business. That's why we ensure that our
          clients receive the highest quality preparation of schedules service
          that meets their specific needs.
        </p>
        <p>
          We use the latest software and technology to streamline the process
          and provide efficient and cost-effective services. Our goal is to help
          our clients achieve their financial objectives by providing timely and
          accurate schedules that enable them to make informed business
          decisions.
        </p>
        <ul>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Rental Schedules:</h4>
          </li>
          <p>
            We provide rental schedule preparation services in Perth to assist
            property investors in accurately recording their rental income and
            expenses, complying with tax regulations, and maximizing their
            deductions.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Capital Gain Schedules:</h4>
          </li>
          <p>
            Capital gain schedules are used to report gains or losses made from
            the sale of capital assets such as property, shares or managed
            funds. We can prepare accurate and timely schedules to assist with
            tax compliance.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Depreciation Schedules:</h4>
          </li>
          <p>
            Depreciation schedules are crucial for businesses to keep track of
            the value of their assets over time. Our team provides comprehensive
            depreciation schedule reports to ensure compliance and accurate
            financial records.
          </p>
          <li className="d-flex flex-row align-items-start">
            <img
              src={DimondIcon}
              style={{ width: "22px", marginRight: "10px", marginTop: "8px" }}
              alt=""
            />
            <h4>Motor Vehicle Schedules:</h4>
          </li>
          <p>
            Motor vehicle schedules are essential documents that record the
            depreciation of a vehicle over time. They are necessary for tax
            purposes and help business owners claim deductions for the
            depreciation of their vehicle.
          </p>
        </ul>
      </Container>
      <SoftwareSection />
      <IndustriesCp />
      <ContainerFullWidth2>
        <ContactUsCp />
      </ContainerFullWidth2>
    </div>
  );
};

export default PreparationSchedulesService;
